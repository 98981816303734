import React, { useState, useEffect } from "react";
import { useGlobalState } from "../GlobalContext";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { toast } from "react-toastify";
import { fetchMyFavorites } from "../Favourites/fetchMyFavourites";
import { favouriteActions } from "../Favourites/favouriteActions";
import HomeIcon from "@mui/icons-material/Home";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import "react-toastify/dist/ReactToastify.css";
import "./styles.css";
import textProperties from "../utils/textProperties";

export default function SortMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const { updateGlobalData } = useGlobalState();
  const { selectedFilters, updateSelectedFilters, updateTotalSitesCount } =
    useGlobalState();
  const [expanded, setExpanded] = useState(false);
  const [user, setUser] = useState(null);

  let toastNum = true;
  const notify = (toastMessage) => {
    if (toastNum) {
      toast.error(toastMessage, {
        position: toast.POSITION.TOP_LEFT,
        autoClose: 3000,
      });
      toastNum = false;
      setInterval(() => {
        toastNum = true;
      }, 7000);
    }
  };
  const handleButtonClicked = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      setUser(authUser);
    });

    return () => unsubscribe();
  }, []);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function filterByLoadLH() {
    // Check if sortType=byLoad already exists in selectedFilters
    const byLoadExists = selectedFilters.some((filter) =>
      filter.includes("sortType: byLoad")
    );

    // If byLoad exists, do nothing; otherwise, proceed with the update
    if (byLoadExists) {
      handleClose();
      return;
    }

    // Check if sortType=byArea already exists in selectedFilters
    const byAreaExists = selectedFilters.some((filter) =>
      filter.includes("sortType: byArea")
    );

    // If byArea exists, remove it; otherwise, keep the existing filters
    const updatedFilters = byAreaExists
      ? selectedFilters.filter((filter) => !filter.includes("sortType: byArea"))
      : selectedFilters;

    // Add sortType=byLoad to the filters
    const sortMethod = { sortType: "byLoad" };
    const newFilter = `sortType: ${sortMethod.sortType}`;
    const updatedFiltersWithLoad = [...updatedFilters, newFilter];

    // Update the selectedFilters array
    updateSelectedFilters(updatedFiltersWithLoad);
    handleClose();
  }

  function filterByArea() {
    // Check if sortType=byArea already exists in selectedFilters
    const byAreaExists = selectedFilters.some((filter) =>
      filter.includes("sortType: byArea")
    );

    // If byArea exists, do nothing; otherwise, proceed with the update
    if (byAreaExists) {
      handleClose();
      return;
    }

    // Check if sortType=byLoad already exists in selectedFilters
    const byLoadExists = selectedFilters.some((filter) =>
      filter.includes("sortType: byLoad")
    );

    // If byLoad exists, remove it; otherwise, keep the existing filters
    const updatedFilters = byLoadExists
      ? selectedFilters.filter((filter) => !filter.includes("sortType: byLoad"))
      : selectedFilters;

    // Add sortType=byArea to the filters
    const sortMethod = { sortType: "byArea" };
    const newFilter = `sortType: ${sortMethod.sortType}`;
    const updatedFiltersWithArea = [...updatedFilters, newFilter];

    // Update the selectedFilters array
    updateSelectedFilters(updatedFiltersWithArea);
    handleClose();
  }

  const handleButtonClick = async () => {
    console.log("Button Clicked!");
    setExpanded(false);
    try {
      const data = await fetchMyFavorites();
      updateTotalSitesCount(0);
      console.log(data);
      if (data?.length > 0) {
        updateGlobalData(data);
      } else {
        notify(textProperties?.notify?.noPreference);
      }
    } catch (error) {
      console.error("Error fetching favorites:", error);
    }
  };

  const handleClearFavourite = () => {
    console.log("Button Clicked!");
    favouriteActions("/clear");
    setExpanded(false);
    updateSelectedFilters([...selectedFilters]);
  };

  const handleHomeButton = () => {
    updateSelectedFilters([]);
    setExpanded(false);
  };

  return (
    <>
      {user?.emailVerified ? (
        <div className="filter-public">
          <div className="my-preferences" title={textProperties?.myPreferences}>
            <div
              className={`preferences-container ${expanded ? "expanded" : ""}`}
            >
              <div className="preferences-icon" onClick={handleButtonClicked}>
                <FavoriteRoundedIcon />
              </div>
              {expanded && (
                <div className="expanded-buttons">
                  <span
                    className="favBtnSpan"
                    title={textProperties?.deletePreferences}
                  >
                    <DeleteForeverIcon
                      className="clearFavouriteBtn"
                      onClick={handleClearFavourite}
                    />
                    <p className="favClearText">{textProperties.clearAll}</p>
                  </span>
                  <span className="favBtnSpan" title={textProperties?.goHome}>
                    <HomeIcon
                      className="openFavouriteBtn"
                      onClick={handleHomeButton}
                    />
                    <p className="favOpenText">{textProperties.homeText}</p>
                  </span>
                  <span
                    className="favBtnSpan"
                    title={textProperties?.openPreferences}
                  >
                    <FavoriteRoundedIcon
                      className="openFavouriteBtn"
                      onClick={handleButtonClick}
                    />
                    <p className="favOpenText">{textProperties.open}</p>
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="filter-menu">
            <Button
              id="fade-button"
              aria-controls={open ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              color="inherit"
              title={textProperties.sortSites}
            >
              <TuneOutlinedIcon />
              {textProperties.sort}
            </Button>
            <Menu
              id="fade-menu"
              MenuListProps={{
                "aria-labelledby": "fade-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
              disableScrollLock={true}
              sx={{
                ".MuiPaper-root": {
                  borderRadius: "1rem",
                  marginTop: "0.5rem",
                  boxShadow: "0 0px 5px 0 rgba(67, 66, 66, 0.2)",
                  minWidth: "200px",
                  overflow: "visible",
                },
              }}
            >
              <MenuItem onClick={filterByLoadLH}>
                {textProperties.sortByLoad}
              </MenuItem>
              <MenuItem onClick={filterByArea}>
                {textProperties.sortByArea}
              </MenuItem>
            </Menu>
          </div>
          <div
            className="dummyOfAboveFilter"
            style={{ marginRight: "2.4rem" }}
          ></div>
        </div>
      ) : null}
    </>
  );
}
