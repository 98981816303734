import {
  apiUrlAuthenticatedSource,
  apiUrlPublic,
} from "../components/utils/constants";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import App from "../firebase";

const auth = getAuth();
async function getRefreshedToken() {
  try {
    const user = auth.currentUser;
    if (user) {
      const refreshedToken = await user.getIdToken();
      return refreshedToken;
    }
    return null;
  } catch (error) {
    console.error("Error refreshing token:", error);
    return null;
  }
}

export async function fetchData(authenticated) {
  try {
    let apiUrl = authenticated ? apiUrlAuthenticatedSource : apiUrlPublic;
    const refreshedToken = await getRefreshedToken();
    const headers = new Headers({
      accept: "application/json",
      Authorization: `Bearer ${refreshedToken}`,
    });

    const response = await fetch(apiUrl + "", {
      method: "GET",
      headers: authenticated ? headers : undefined,
    });
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }

    const data = await response.json();
    const totalCount = response.headers.get("x-total-count");
    // console.log(totalCount);
    return { data, totalCount };
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

// onAuthStateChanged(auth, async (user) => {
//   if (user?.emailVerified) {
//     // Fetch data when the user is authenticated
//     try {
//       const data = await fetchData(true);
//       // Update your component's state or perform any necessary actions with the data here
//       // console.log("Data fetched after authentication:", data);
//     } catch (error) {
//       console.error("Error fetching data after authentication:", error);
//     }
//   } else {
//     // Fetch data when the user is not authenticated
//     try {
//       const data = await fetchData(false);
//       // Update your component's state or perform any necessary actions with the data here
//       // console.log("Data fetched when not authenticated:", data);
//     } catch (error) {
//       console.error("Error fetching data when not authenticated:", error);
//     }
//   }
// });
