import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo/logo.png";
function SecondNavbar() {
  return (
    <div className="second-navbar">
      <div className="cpo-logo">
        <Link to="/">
          <img
            src={logo}
            alt="CPO-Assist-logo"
            className="second-navbar-logo"
          />
        </Link>
      </div>
    </div>
  );
}

export default SecondNavbar;
