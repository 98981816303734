import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { toast } from "react-toastify";
import textProperties from "../utils/textProperties";

export function sendPasswordResetCall(email) {
  const notify = (toastMessage) => {
    toast.error(toastMessage, {
      position: toast.POSITION.TOP_LEFT,
      autoClose: 5000,
    });
  };
  const auth = getAuth();
  sendPasswordResetEmail(auth, email)
    .then(() => {
      notify(textProperties.notify.passwordReset + email);
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      notify("Error: " + errorMessage + "Code: " + errorCode);
    });
}
