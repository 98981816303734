import React, { useState } from "react";
import Button from "@mui/material/Button";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CancelIcon from '@mui/icons-material/Cancel';
import { createPopper } from "@popperjs/core";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

const PopperComponent = ({onRemoveClick, onWishlistClick}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [popper, setPopper] = useState(null);
  const [open, setOpen] = useState(false);

  const handleToggle = (event) => {
    if (popper) {
      popper.destroy();
    }
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setPopper(createPopper(anchorEl, anchorEl, { placement: "bottom" }));
    setOpen(!open);
  };

  const handleClose = () => {
    if (popper) {
      popper.destroy();
      setPopper(null);
    }
    setAnchorEl(null);
    setOpen(false);
  };

  const onRemoveClicked = () => {
    onRemoveClick();
    handleClose();
  }
  const onWishlistClicked = () => {
    onWishlistClick();
    handleClose();
  }

  const id = open ? "simple-popper" : undefined;

  return (
    <div>
      <DeleteRoundedIcon variant="contained" onClick={handleToggle} />
      {/* Open Popper */}
      {/* </Button> */}
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom"
        modifiers={[{ name: "flip", enabled: false }]} // Optional: Disable flip modifier if needed
        className="popperComponent"
      >
        {({ TransitionProps }) => (
          <Paper {...TransitionProps}>
            <CancelIcon className="popperCloseBtn" onClick={() => {setOpen(false)}}/>
            <Typography
              variant="h6"
              leftBottom
              style={{ padding: "10px", fontWeight: "400", paddingBottom: "0", borderBottom: "1px solid grey" }}
            >
              Remove site from cart?
            </Typography>
            <Typography paragraph style={{ padding: "10px" }}>
              You can save it for later.
            </Typography>
            <div className="popperButtons">
              <Button
                variant="contained"
                className="popperRemoveBtn"
                onClick={onRemoveClicked}
              >
                Remove
              </Button>
              <Button
                variant="contained"
                className="popperWishlistBtn"
                onClick={onWishlistClicked}
              >
                Save for later
              </Button>
            </div>
          </Paper>
        )}
      </Popper>
    </div>
  );
};

export default PopperComponent;
