import React, { useState, useEffect } from "react";
import { fetchProfileData } from "../utils/constants";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { updateProfileData } from "./updateProfileData";
import ProfilePhotoUploader from "./ProfilePhotoUploader";
import { sendEmailVerificationEmail } from "../Authentication/sendEmailVerificationEmail";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import { sendPasswordResetCall } from "../Authentication/sendPasswordResetEmail";
import GuestUserPage from "../utils/GuestUserPage";
import DefaultDP from "../../assets/images/defaultpp.png";
import LoadingModal from "../utils/LoadingModal";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {
  Paper,
  Typography,
  Button,
  Grid,
  TextField,
  styled,
  Box,
} from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import textProperties from "../utils/textProperties";
import {
  isMobileNumberValid,
  isEmailValid,
  isNameValid,
  // isNumber,
  isPanNumberValid,
  isGSTNumberValid,
  isCompanyEmailValid,
  isValidIndianPhoneNumber,
} from "../utils/checks";
import "./styles.css";

const RootPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: "10px",
  border: "1px solid #ea5355",
  width: "80%",
  margin: "auto",
}));

const EditButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#ea5355",
  color: "white",
  marginTop: theme.spacing(2),
}));

const ProfilePage = () => {
  const [user, setUser] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const [profilePic, setProfilePic] = useState("");
  const [profileData, setProfileData] = useState();
  const [verificationEmailSent, setVerificationEmailSent] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isGuest, setIsGuest] = useState(false); //change to false
  const [isFirstNameFocused, setIsFirstNameFocused] = useState(false);
  const [isLastNameFocused, setIsLastNameFocused] = useState(false);
  const [isMobileNumberFocused, setIsMobileNumberFocused] = useState(false);
  const [isEmailFocused, setIsEmailFocused] = useState(false);
  const [isCompanyNameFocused, setIsCompanyNameFocused] = useState(false);
  const [isGSTFocused, setIsGSTFocused] = useState(false);
  const [isPANFocused, setIsPANFocused] = useState(false);
  const [isCompanyPhoneFocused, setIsCompanyPhoneFocused] = useState(false);
  const [isCompanyAddressFocused, setIsCompanyAddressFocused] = useState(false);
  const handleEditClick = () => {
    setIsEditing(true);
  };
  const allFieldsValid = () => {
    const validFirstName = isNameValid(addProfileData?.firstName);
    const validLastName = isNameValid(addProfileData?.lastName);
    const validEmail = isEmailValid(addProfileData?.email);
    const validMobileNumber = isMobileNumberValid(addProfileData?.mobileNumber);
    const validCompanyName = addProfileData?.companyName?.length > 0;
    const companyPhone = isValidIndianPhoneNumber(addProfileData?.phoneNumber);
    const companyEmail = isCompanyEmailValid(addProfileData?.companyEmail);
    const companyPAN = isPanNumberValid(addProfileData?.panNumber);
    const companyGST = isGSTNumberValid(addProfileData?.gstNumber);

    return (
      validFirstName &&
      validLastName &&
      validEmail &&
      validMobileNumber &&
      validCompanyName &&
      companyPhone &&
      companyEmail &&
      companyPAN &&
      companyGST
    );
  };

  const notify = (toastMessage) => {
    toast.error(toastMessage, {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 5000,
    });
  };

  const handleEmailVerificationClick = () => {
    sendEmailVerificationEmail();
    setVerificationEmailSent(true);
  };

  useEffect(() => {
    if (profileData?.role?.toUpperCase() === "GUEST") {
      setIsGuest(true);
    }
  }, [profileData]);

  const handleMobileNumberFocus = (value) => {
    setIsMobileNumberFocused(value);
  };
  const handleEmailFocus = (value) => {
    setIsEmailFocused(value);
  };
  const handleFirstNameFocus = (value) => {
    setIsFirstNameFocused(value);
    console.log(isFirstNameFocused);
  };
  const handleLastNameFocus = (value) => {
    setIsLastNameFocused(value);
  };

  const handleGSTFocus = (value) => {
    setIsGSTFocused(value);
  };

  const handlePANFocused = (value) => {
    setIsPANFocused(value);
  };

  const handleCompanyNameFocused = (value) => {
    setIsCompanyNameFocused(value);
  };

  const handleCompanyPhoneFocused = (value) => {
    setIsCompanyPhoneFocused(value);
  };

  const handleCompanyAddressFocus = (value) => {
    setIsCompanyAddressFocused(value);
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setOpenModal(true);
        try {
          let apiUrl = fetchProfileData;
          const refreshedUser = await user.getIdToken();
          const headers = {
            Authorization: `Bearer ${refreshedUser}`,
          };
          const response = await fetch(apiUrl, {
            method: "GET",
            headers,
          });

          if (!response.ok) {
            setOpenModal(false);
            notify(textProperties.notify.profileFetchError);
            throw new Error("Failed to fetch data");
          }

          const { data } = await response.json();
          setProfileData(data);
          setProfilePic(data?.profilePic);
          setOpenModal(false);
        } catch (error) {
          setOpenModal(false);
          notify(textProperties.notify.profileFetchError);
          console.error("Error fetching data:", error);
        }
      }
    });

    return () => unsubscribe();
  }, []);

  const [addProfileData, setAddProfileData] = useState({
    role: "",
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    companyName: "",
    gstNumber: "",
    panNumber: "",
    companyAddress: "",
    companyEmail: "",
    phoneNumber: "",
  });

  useEffect(() => {
    if (profileData) {
      setAddProfileData({
        role: profileData.role || "",
        firstName: profileData.firstName || "",
        lastName: profileData.lastName || "",
        mobileNumber: profileData.mobileNumber || "",
        email: profileData.email || "",
        companyName: profileData.companyName || "",
        gstNumber: profileData.gstNumber || "",
        panNumber: profileData.panNumber || "",
        companyAddress: profileData.companyAddress || "",
        companyEmail: profileData.companyEmail || "",
        phoneNumber: profileData.phoneNumber || "",
      });
    }
  }, [profileData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddProfileData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveChanges = () => {
    if (allFieldsValid) {
      updateProfileData(addProfileData);
    }
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      setUser(authUser);
    });

    return () => unsubscribe();
  }, []);

  function emailPasswordResetCall() {
    sendPasswordResetCall(profileData.email);
  }

  return (
    <div className="profile-container">
      <LoadingModal
        open={openModal}
        onClose={openModal}
        text={"Loading! Please wait..."}
      />
      <ToastContainer theme="colored" />

      <Header />
      {user !== null ? (
        <>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            marginBottom="4rem"
          >
            <RootPaper elevation={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3.8} style={{ paddingTop: 25 }}>
                  <div className="profilePictureDiv">
                    {profilePic ? (
                      <img
                        src={profilePic}
                        alt={"Profile img"}
                        style={{
                          width: "100%",
                          height: "368px",
                          borderRadius: "1rem",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <img
                        src={DefaultDP}
                        alt={"Profile img"}
                        style={{
                          width: "100%",
                          height: "368px",
                          borderRadius: "1rem",
                          objectFit: "cover",
                        }}
                      />
                    )}
                  </div>{" "}
                  <ProfilePhotoUploader />
                </Grid>
                <Grid item xs={12} md={8.2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" className="profileH6">
                        {textProperties.firstName}
                      </Typography>
                      <TextField
                        name="firstName"
                        variant="outlined"
                        required
                        fullWidth
                        size="small"
                        value={addProfileData?.firstName}
                        onFocus={() => handleFirstNameFocus(true)}
                        onBlur={() => setIsFirstNameFocused(false)}
                        placeholder={profileData?.firstName}
                        onChange={handleChange}
                        disabled={!isEditing}
                        className="nameProfile"
                        InputProps={{
                          style: {
                            borderRadius: "10px",
                          },
                        }}
                        error={
                          isEditing && !isNameValid(addProfileData?.firstName)
                        }
                        helperText={
                          isFirstNameFocused &&
                          isEditing &&
                          !isNameValid(addProfileData?.firstName)
                            ? textProperties.userDetails.invalidFirstName
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" className="profileH6">
                        {textProperties.lastName}
                      </Typography>
                      <TextField
                        name="lastName"
                        variant="outlined"
                        required
                        fullWidth
                        size="small"
                        value={addProfileData?.lastName}
                        onChange={handleChange}
                        onFocus={() => handleLastNameFocus(true)}
                        onBlur={() => handleLastNameFocus(false)}
                        disabled={!isEditing}
                        InputProps={{
                          style: {
                            borderRadius: "10px",
                          },
                        }}
                        error={
                          isEditing && !isNameValid(addProfileData?.lastName)
                        }
                        helperText={
                          isLastNameFocused &&
                          isEditing &&
                          !isNameValid(addProfileData?.lastName)
                            ? textProperties.userDetails.invalidLastName
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ paddingTop: 1 }}>
                      <Typography variant="h6" className="profileH6">
                        {textProperties.email}
                      </Typography>
                      <TextField
                        name="email"
                        variant="outlined"
                        required
                        fullWidth
                        size="small"
                        value={addProfileData?.email}
                        onChange={handleChange}
                        disabled={true}
                        InputProps={{
                          style: {
                            borderRadius: "10px",
                          },
                        }}
                        error={
                          isEditing && !isEmailValid(addProfileData?.email)
                        }
                        helperText={
                          isEditing && !isEmailValid(addProfileData?.email)
                            ? textProperties.userDetails.invalidEmail
                            : ""
                        }
                      />
                      {user ? (
                        !user.emailVerified ? (
                          <>
                            <span>
                              {verificationEmailSent ? (
                                <span className="unverifiedTextSent">Sent</span>
                              ) : (
                                <span
                                  className="unverifiedText"
                                  onClick={handleEmailVerificationClick}
                                >
                                  {" "}
                                  Verify
                                </span>
                              )}
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="verifiedEmailText">
                              {textProperties.verified}
                            </span>
                            <VerifiedIcon className="verifiedEmailIcon" />
                          </>
                        )
                      ) : null}
                    </Grid>
                    <Grid item xs={12} md={6} style={{ paddingTop: 1 }}>
                      <Typography variant="h6" className="profileH6">
                        {textProperties.mobileNumber}
                      </Typography>
                      <TextField
                        name="mobileNumber"
                        variant="outlined"
                        required
                        fullWidth
                        size="small"
                        value={addProfileData?.mobileNumber}
                        onChange={handleChange}
                        onFocus={() => handleMobileNumberFocus(true)}
                        onBlur={() => handleMobileNumberFocus(false)}
                        disabled={!isEditing}
                        InputProps={{
                          style: {
                            borderRadius: "10px",
                          },
                        }}
                        error={
                          isEditing &&
                          !isMobileNumberValid(addProfileData?.mobileNumber)
                        }
                        helperText={
                          isMobileNumberFocused &&
                          isEditing &&
                          !isMobileNumberValid(addProfileData?.mobileNumber)
                            ? textProperties.userDetails.invalidMobile
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ paddingTop: 1 }}>
                      <Typography variant="h6" className="profileH6">
                        {textProperties.companyName}
                      </Typography>
                      <TextField
                        name="companyName"
                        variant="outlined"
                        required
                        fullWidth
                        size="small"
                        value={
                          !isGuest
                            ? addProfileData?.companyName
                            : textProperties.notRequired
                        }
                        onChange={handleChange}
                        onFocus={() => handleCompanyNameFocused(true)}
                        onBlur={() => handleCompanyNameFocused(false)}
                        disabled={!isEditing || isGuest}
                        InputProps={{
                          style: {
                            borderRadius: "10px",
                          },
                        }}
                        error={
                          isEditing && addProfileData?.companyName.trim() === ""
                        }
                        helperText={
                          isCompanyNameFocused &&
                          !isGuest &&
                          isEditing &&
                          addProfileData?.companyName.trim() === ""
                            ? textProperties.userDetails.invalidBlankField
                            : ""
                        }
                      />
                    </Grid>

                    <Grid item xs={12} md={6} style={{ paddingTop: 1 }}>
                      <Typography variant="h6" className="profileH6">
                        {textProperties.phoneNumber}
                      </Typography>
                      <TextField
                        name="phoneNumber"
                        variant="outlined"
                        required
                        fullWidth
                        size="small"
                        value={
                          !isGuest
                            ? addProfileData?.phoneNumber
                            : textProperties.notRequired
                        }
                        onChange={handleChange}
                        onFocus={() => handleCompanyPhoneFocused(true)}
                        onBlur={() => handleCompanyPhoneFocused(false)}
                        disabled={!isEditing || isGuest}
                        InputProps={{
                          style: {
                            borderRadius: "10px",
                          },
                        }}
                        error={
                          isEditing &&
                          !isValidIndianPhoneNumber(addProfileData?.phoneNumber)
                        }
                        helperText={
                          isCompanyPhoneFocused &&
                          !isGuest &&
                          isEditing &&
                          !isValidIndianPhoneNumber(addProfileData?.phoneNumber)
                            ? textProperties.userDetails.invalidNumber
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ paddingTop: 1 }}>
                      <Typography variant="h6" className="profileH6">
                        {textProperties.companyEmail}
                      </Typography>
                      <TextField
                        name="companyEmail"
                        variant="outlined"
                        required
                        fullWidth
                        size="small"
                        value={
                          !isGuest
                            ? addProfileData?.companyEmail
                            : textProperties.notRequired
                        }
                        onChange={handleChange}
                        onFocus={() => handleEmailFocus(true)}
                        onBlur={() => handleEmailFocus(false)}
                        disabled={!isEditing || isGuest}
                        InputProps={{
                          style: {
                            borderRadius: "10px",
                          },
                        }}
                        error={
                          isEditing &&
                          !isCompanyEmailValid(addProfileData?.companyEmail)
                        }
                        helperText={
                          isEmailFocused &&
                          !isGuest &&
                          isEditing &&
                          !isCompanyEmailValid(addProfileData?.companyEmail)
                            ? textProperties.userDetails.invalidCompanyEmail
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ paddingTop: 1 }}>
                      <Typography
                        variant="h6"
                        className="profileH6"
                      >
                        {textProperties.panNumber}
                      </Typography>
                      <TextField
                        name="panNumber"
                        variant="outlined"
                        required
                        fullWidth
                        size="small"
                        value={
                          !isGuest
                            ? addProfileData.panNumber
                            : textProperties.notRequired
                        }
                        onChange={handleChange}
                        onFocus={() => handlePANFocused(true)}
                        onBlur={() => handlePANFocused(false)}
                        disabled={!isEditing || isGuest}
                        InputProps={{
                          style: {
                            borderRadius: "10px",
                          },
                        }}
                        error={
                          !isGuest &&
                          isEditing &&
                          !isPanNumberValid(addProfileData?.panNumber)
                        }
                        helperText={
                          isPANFocused &&
                          !isGuest &&
                          isEditing &&
                          !isPanNumberValid(addProfileData?.panNumber)
                            ? textProperties.userDetails.invalidPAN
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ paddingTop: 1 }}>
                      <Typography variant="h6" className="profileH6">
                        {textProperties.gstNumber}
                      </Typography>
                      <TextField
                        name="gstNumber"
                        variant="outlined"
                        required
                        fullWidth
                        size="small"
                        value={
                          !isGuest
                            ? addProfileData.gstNumber
                            : textProperties.notRequired
                        }
                        onChange={handleChange}
                        onFocus={() => handleGSTFocus(true)}
                        onBlur={() => handleGSTFocus(false)}
                        disabled={!isEditing || isGuest}
                        InputProps={{
                          style: {
                            borderRadius: "10px",
                          },
                        }}
                        error={
                          !isGuest &&
                          isEditing &&
                          !isGSTNumberValid(addProfileData?.gstNumber)
                        }
                        helperText={
                          isGSTFocused &&
                          !isGuest &&
                          isEditing &&
                          !isGSTNumberValid(addProfileData?.gstNumber)
                            ? textProperties.userDetails.invalidGST
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ paddingTop: 1 }}>
                      <Typography variant="h6" className="profileH6">
                        {textProperties.role}
                      </Typography>
                      <TextField
                        name="role"
                        variant="outlined"
                        required
                        fullWidth
                        size="small"
                        value={addProfileData.role.toUpperCase()}
                        onChange={handleChange}
                        disabled={true}
                        InputProps={{
                          style: {
                            borderRadius: "10px",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12.4} style={{ paddingTop: 1 }}>
                    <Typography variant="h6" className="profileH6">
                      {textProperties.companyAddress}
                    </Typography>
                    <TextField
                      name="companyAddress"
                      variant="outlined"
                      required
                      fullWidth
                      size="small"
                      value={
                        !isGuest
                          ? addProfileData?.companyAddress
                          : textProperties.notRequired
                      }
                      onChange={handleChange}
                      onFocus={() => handleCompanyAddressFocus(true)}
                      onBlur={() => handleCompanyAddressFocus(false)}
                      disabled={!isEditing || isGuest}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                      error={
                        isEditing &&
                        addProfileData?.companyAddress.trim() === ""
                      }
                      helperText={
                        isCompanyAddressFocused &&
                        !isGuest &&
                        isEditing &&
                        addProfileData?.companyAddress.trim() === ""
                          ? textProperties.userDetails.invalidBlankField
                          : ""
                      }
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} mt={-2} fullWidth>
                  {!isEditing ? (
                    <EditButton
                      className="profile-btn"
                      variant="contained"
                      fullWidth
                      onClick={handleEditClick}
                    >
                      {textProperties.editProfile}
                    </EditButton>
                  ) : (
                    <>
                      {showPasswordChange ? (
                        <>
                        </>
                      ) : (
                        <>
                          <Button
                            variant="contained"
                            className="profile-btn"
                            fullWidth
                            onClick={emailPasswordResetCall}
                          >
                            {textProperties.resetPassword}
                          </Button>
                        </>
                      )}
                      <Button
                        variant="contained"
                        className="profile-btn"
                        fullWidth
                        style={{ margin: "1rem 0" }}
                        onClick={handleSaveChanges}
                      >
                        {textProperties.saveChanges}
                      </Button>
                    </>
                  )}
                </Grid>
              </Grid>
            </RootPaper>
          </Box>
        </>
      ) : (
        <GuestUserPage message={textProperties.guestMessage} />
      )}
      <Footer />
    </div>
  );
};
export default ProfilePage;
