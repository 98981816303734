import React from "react";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
function LogoutButton({ width }) {
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      const auth = getAuth();
      await signOut(auth).then(() => {
        navigate("/");
        window.location.reload();
      });

      // Sign the user out
      // You can optionally redirect the user to a different page here
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <span onClick={handleLogout} style={{ width: [width] }}>
      Logout
    </span>
  );
}

export default LogoutButton;
