import React from "react";

function Drafts() {
  return (
    <div className="draftsContainer">
      <div className="draftsTopBar">Drafts</div>
      <div className="draftItemsContainer">
        <div className="draftCard">
            <div className="draftLeft">Site name</div>
            <div className="draftCenter">Saved</div>
            <div className="draftRight">Other details</div>
        </div>

        <div className="draftCard">
            <div className="draftLeft">Site name</div>
            <div className="draftCenter">Saved</div>
            <div className="draftRight">Other details</div>
        </div>

        <div className="draftCard">
            <div className="draftLeft">Site name</div>
            <div className="draftCenter">Saved</div>
            <div className="draftRight">Other details</div>
        </div>
      </div>
    </div>
  );
}

export default Drafts;
