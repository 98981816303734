import * as React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import { Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { sendPasswordResetCall } from "./sendPasswordResetEmail";
import LoadingModal from "../utils/LoadingModal";
import { isEmailValid } from "../utils/checks";
import { sendEmailVerificationEmail } from "./sendEmailVerificationAllUser";
import "./styles.css";
import textProperties from "../utils/textProperties";

function SignIn({ value, width }) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [openVerification, setOpenVerification] = React.useState(false);
  const [verificationText, setVerificationText] = React.useState(false);
  const [verificationModal, setVerificationModal] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setIsModalOpen(false);
  };
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); // Added state for error message
  const [isModalOpen, setIsModalOpen] = useState(false);

  const auth = getAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isEmailValid(email)) {
      setIsModalOpen(true);
      try {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );

        const user = userCredential.user;

        handleClose();
        // navigate("/");
        console.log(user);
      } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode);
        console.log(errorMessage);

        setIsModalOpen(false);
        if (errorCode === "auth/error-code:-47") {
          setOpenVerification(true);
        } else if (errorMessage.includes("not verified")) {
          setErrorMessage(textProperties?.firebaseError?.verifyEmail);
          setOpenVerification(true);
        } else {
          setErrorMessage(textProperties?.firebaseError?.[errorCode]);
        }
      }
    } else {
      setErrorMessage(textProperties?.userDetails?.invalidEmail); // Set the error message
    }
  };

  function passwordResetEmailCall() {
    setIsButtonDisabled(true);
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 12000);
    sendPasswordResetCall(email);
  }

  const handleSendVerificationEmail = async () => {
    setVerificationModal(true);
    try {
      const response = await sendEmailVerificationEmail(email);
      console.log("Verification email sent successfully:", response);
      setVerificationModal(false);
      setVerificationText(true);

      // Handle the response as needed
    } catch (error) {
      console.error("Error sending verification email:", error);
      // setErrorMessage("Network error: Please try again");
      setVerificationModal(false);
      setOpenVerification(true);
      setVerificationText(false);

      // Handle the error as needed
    }

    console.log(email);
  };

  const defaultTheme = createTheme();

  return (
    <div className="signin-Modal">
      <LoadingModal open={isModalOpen} text={"Signing in..."} />
      <LoadingModal open={verificationModal} text={"Please wait..."} />
      <span
        onClick={handleOpen}
        className="login-span"
        style={{ width: [width] }}
      >
        {value}
      </span>
      <Modal
        open={open}
        onClose={handleClose}
        onKeyDown={(e) => e.stopPropagation()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "grey",
          margin: "auto",
        }}
      >
        <>
          <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backgroundColor: "white",
                  opacity: "1 !important",
                  borderRadius: "2rem",
                  padding: "3rem",
                  boxShadow: "0 5px 5rem 15px gray",
                  height: "37rem",
                }}
              >
                <Avatar sx={{ m: 0, bgcolor: "rgb(234, 83, 85)" }}>
                  <LockOutlinedIcon style={{ color: "white" }} />
                </Avatar>
                <Typography component="h1" variant="h5">
                  {textProperties.signIn}
                </Typography>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ mt: 1 }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={textProperties.emailAddress}
                    name={textProperties.email}
                    autoComplete="email"
                    autoFocus
                    onChange={(e) => setEmail(e.target.value)}
                    inputProps={{
                      style: { borderColor: "rgb(234, 83, 85)" }, // Set the outline color to red //
                    }}
                    error={email?.length > 0 && !isEmailValid(email)}
                    helperText={
                      email?.length > 0 && !isEmailValid(email)
                        ? textProperties.userDetails.invalidEmail
                        : ""
                    }
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={textProperties.password}
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="remember"
                        style={{ color: "rgb(234, 83, 85)" }}
                      />
                    }
                    label={textProperties.rememberMe}
                  />
                  {/* Error message */}
                  {errorMessage && (
                    <p
                      style={{
                        color: "red",
                        textAlign: "center",
                        margin: "1px",
                      }}
                    >
                      {errorMessage}
                    </p>
                  )}

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    style={{
                      backgroundColor: "rgb(234, 83, 85)",
                      color: "white",
                    }}
                  >
                    {textProperties.signIn}
                  </Button>
                  <div className="signin-bottom">
                    <Grid container>
                      <Grid item xs>
                        {!isButtonDisabled && (
                          <div>
                            <Link
                              href="#"
                              variant="body2"
                              onClick={passwordResetEmailCall}
                              style={{ color: "#ea5355" }}
                            >
                              {textProperties.forgotPassword}
                            </Link>
                          </div>
                        )}
                      </Grid>
                      <Grid item style={{ marginLeft: "2rem" }}>
                        {textProperties.newUser}{" "}
                        <Link
                          to="/signup"
                          variant="body2"
                          className="link-text"
                          onClick={handleClose}
                          style={{ color: "rgb(234, 83, 85)" }}
                        >
                          {textProperties.signUp}
                        </Link>
                      </Grid>
                    </Grid>
                  </div>
                </Box>
                {openVerification && (
                  <>
                    {!verificationText ? (
                      <Grid
                        container
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          color: "#ea5355",
                          marginLeft: "5px",
                          backgroundColor: "#fff",
                          width: "200px",
                          borderRadius: "5px",
                          border: "1px solid #ea5355",
                          cursor: "pointer",
                        }}
                        onClick={handleSendVerificationEmail}
                      >
                        Send Verification Email
                      </Grid>
                    ) : (
                      <Grid
                        container
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          color: "#ea5355",
                          marginLeft: "5px",
                          backgroundColor: "#fff",
                          width: "200px",
                          borderRadius: "5px",
                          border: "1px solid #ea5355",
                        }}
                      >
                        Sent
                      </Grid>
                    )}
                  </>
                )}
                <Button
                  onClick={handleClose}
                  style={{ marginTop: "1rem", color: "rgb(234, 83, 85)" }}
                >
                  {textProperties.back}
                </Button>
              </Box>
            </Container>
          </ThemeProvider>
        </>
      </Modal>
    </div>
  );
}

export default SignIn;
