export const textPropertiesAdmin = {
  name: "This field requires a name for the site",
  city: "This field requires the city where the site is located",
  pincode: "This field requires the pincode of the area of the site",
  state: "This field requires the state in which the site city is located",
  locality:
    "This field requires the locality (building name, road name, etc.) where the site is located",
  address: "This field requires the complete address of the site location",
  propertyType: "Please select the type of property where site is located",
  siteType: "Please select the type of site (If does not exist in dropdown, type it in the text field on the right side)",
  parkings: "Total number of parking spaces available",
  access: "Accessibility of the site location",
  suitability: "Which type of vechiles are suitable for the site location",
  parkingType: "What is the type of place where the parking exists",
  facilities:
    "Nearby facilities & amenities near the site location (multiple selections allowed)",
  load: "Available electricity load for chargers at the site (Kilowatts)",
  area: "Available area for the EV-station at the site location (Sq. m)",
  about: "Please write a description about the site location",
  price: "Please enter price for the site (Exclusive of all taxes)",
  ownerName: "Please fill in the name of the owner of site",
  ownerContact: "Please fill in the contact number for the owner of site",
  landingPageImages:
    "Please select your landing page images (multiple selections allowed)",
  otherImages:
    "Please select your other images of the site (multiple selections allowed)",

  role: [
    "Roles",
    "Please select a role for which you want to sign up for this site.",
  ],
  cpo: ["CPO", "A Charge Point Operator."],
  lcp: ["LCP", "A Local Charging Partner."],
  guest: [
    "Guest",
    "A guest is someone who just wants to check in and explore the portal and it's features.",
  ],
  firstName: ["First Name", "Please enter your first name."],
  lastName: ["Last Name", "Please enter your last name."],
  mobileNumber: ["Mobile Number", "Please enter a valid mobile number."],
  email: ["Email Address", "Please enter your email address."],
  password: ["Password", "Please enter your password."],
  onBlurGuest: ["All the fields with (*) are mandatory!"],
  onBlurCpoLcp: [
    "** A CPO or LCP must also fill all the Additional details for successful registration! **",
  ],
  companyName: ["Company Name", "Please enter your company name"],
  gstNumber: ["GST Number", "Please enter a valid GST number."],
  panNumber: ["Pan Number", "Please enter a valid PAN number."],
  companyAddress: ["Company Address", "Please enter your company address"],
  companyEmail: ["Company Email", "Please enter your company email id"],
  companyPhone: ["Company Phone", "Please enter your company phone number"],
};
