import React from "react";
import SignIn from "../Authentication/Signin";
import "./utilStyles.css";
function GuestUserPage({ message }) {

  return (
    <div className="guestContainer">
      <div className="guestSignin">
        <SignIn value="Sign in" />
      </div>
      <div className="guestMessage">{message}.</div>
    </div>
  );
}

export default GuestUserPage;
