import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Carousel from "react-material-ui-carousel";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useState, useEffect } from "react";
import CPOImage from "../../assets/images/emptycart.jpeg";
import { Button } from "@mui/material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "rgba(0,0,0,0.5)",
  color: "white",
  borderRadius: "1rem",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function NestedModal({
  imageURL,
  classType,
  altType,
  // imageName,
}) {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      setUser(authUser);
    });
    return () => unsubscribe();
  }, []);

  let images = [imageURL];
  if (user) {
    images = [
      imageURL,
      CPOImage,
      "https://images.unsplash.com/photo-1617886322168-72b886573c35?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fGV2JTIwY2hhcmdpbmd8ZW58MHx8MHx8fDA%3D",
      "https://images.unsplash.com/photo-1607171028974-319ba56cb013?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fGV2JTIwY2hhcmdpbmd8ZW58MHx8MHx8fDA%3D",
      "https://images.unsplash.com/photo-1592919365284-a57ad163e07a?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8ZXYlMjBjaGFyZ2VyfGVufDB8fDB8fHww",
      "https://images.unsplash.com/photo-1671785120538-c24cbe823ccc?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGV2JTIwY2hhcmdlfGVufDB8fDB8fHww",
    ];
  }

  const uniqueSet = new Set();
  const filteredImages = [];

  for (const item of images) {
    if (item?.startsWith("https://")) {
      // If the item is an imageURL and not already in the set, add it to the set and the filtered array
      if (!uniqueSet.has(item)) {
        uniqueSet.add(item);
        filteredImages.push(item);
      }
    } else {
      // If the item is not an imageURL, add it to the filtered array (maintaining order)
      if (!uniqueSet.has(item)) {
        filteredImages.push(item);
        uniqueSet.add(item);
      }
    }
  }

  return (
    <div>
      <img
        src={imageURL}
        alt={altType}
        onClick={handleOpen}
        className={classType}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style }}>
          <h2 id="parent-modal-title">{altType}</h2>
          {/* <p id="parent-modal-description">{imageName}</p> */}
          {/* <img src={imageURL} alt={altType} className='modalExpandedImage'/> */}
          <Carousel
            className="carouselmobile"
            animation="slide"
            swipe
          >
            {filteredImages.map((image, index) => (
              <>
                {/* <p id="parent-modal-description">{imageName}</p> */}

                <img
                  src={image}
                  alt={index}
                  key={index}
                  // className="mobileViewImage"
                  className="modalExpandedImage"
                />
              </>
            ))}
          </Carousel>
        </Box>
      </Modal>
    </div>
  );
}
