import { getAuth } from "firebase/auth";
import { useGlobalState } from "../components/GlobalContext";
import {
  apiQueryAuthenticated,
  apiQueryPublic,
} from "../components/utils/constants";
import { useEffect } from "react";

function FilterAPICalls() {
  const { updateGlobalData, selectedFilters, updateTotalSitesCount } =
    useGlobalState();

  const auth = getAuth();
  const user = auth.currentUser;

  async function getRefreshedToken() {
    try {
      if (user) {
        // const refreshedToken = await user.getIdToken(/* forceRefresh */ true);
        const refreshedToken = await user.getIdToken();
        return refreshedToken;
      }
      return null;
    } catch (error) {
      console.error("Error refreshing token:", error);
      return null;
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = auth.currentUser;
        const queryString = selectedFilters
          .map((filter) => {
            const [filterName, values] = filter.split(": ");
            if (
              filterName === "limit" ||
              filterName === "cities" ||
              filterName === "propertyTypes" ||
              filterName === "pincode" ||
              filterName === "sortType"
            ) {
              const keyValuePairs = values
                .split(", ")
                .map((value) => `${filterName}=${value}`);
              return keyValuePairs.join("&");
            }
            return "";
          })
          .filter(Boolean)
          .join("&");

        let apiUrl = apiQueryPublic + queryString;

        if (user?.emailVerified) {
          apiUrl = apiQueryAuthenticated + queryString;
        }
        const refreshedToken = await getRefreshedToken();
        const headers = new Headers({
          accept: "application/json",
          Authorization: `Bearer ${refreshedToken}`,
        });
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: user ? headers : undefined,
        });

        if (response.ok) {
          const data = await response.json();
          const totalCount = response.headers.get("x-total-count");
          updateGlobalData(data?.data);
          updateTotalSitesCount(totalCount);
        } else {
          console.error("Error fetching the data:");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (auth.currentUser) {
      fetchData();
    }
  }, [selectedFilters, auth]);

  return null; // Replace with your actual component JSX
}

export default FilterAPICalls;
