import React from 'react'
import Approvals from "./Approvals";
function Dashboard() {
  return (
    <div>
      <Approvals/>
    </div>
  )
}

export default Dashboard