import React from "react";
import "./styles.css";
function Approvals() {
  return (
    <div className="approvalContainer">
      <div className="approvalSubHeading">Approvals</div>
      <div className="approvalCards">
        <div className="approvalCard">
          <div className="approvalCardLeft">
            <div className="approvalCardSiteName">
              <span className="bold">Site name:</span>{" "}
              <span className="cpofontGrey">EV station</span>
            </div>
            <div className="approvalCardSiteCity">
              <span className="bold">Site City:</span>
              <span className="cpofontGrey"> Jaipur</span>
            </div>
          </div>
          <div className="approvalCardCenter">
            <div className="approvalStatus">
              {/* <div className="approvalStatusPending">Pending</div> */}
              {/* <div className="approvalStatusApproved">Approved</div> */}
              <div className="approvalStatusRejected">Rejected</div>
            </div>
          </div>
          <div className="approvalCardRight">
            <div className="approvalCardComment">Comments:</div>
            <div className="approvalCardCommentText">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Debitis
              modi accusantium unde commodi expedita neque iusto eum
              exercitationem quia illo inventore illum sit et quo in vero vitae
              odit error nam, suscipit ut eveniet? Molestias accusamus odio
              blanditiis facilis id repellat, nisi consectetur optio eius. Id
              soluta quisquam vero minima!
            </div>
          </div>
        </div>

        <div className="lineBreak"></div>
        <div className="approvalCard"></div>
        <div className="lineBreak"></div>
        <div className="approvalCard"></div>
        <div className="approvalCard"></div>
        <div className="lineBreak"></div>
        <div className="approvalCard"></div>
        <div className="approvalCard"></div>
        <div className="lineBreak"></div>
        <div className="approvalCard"></div>
        <div className="approvalCard"></div>
        <div className="lineBreak"></div>
        <div className="approvalCard"></div>
        <div className="approvalCard"></div>
        <div className="lineBreak"></div>
        <div className="approvalCard"></div>
      </div>
    </div>
  );
}

export default Approvals;
