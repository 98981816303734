export const apiUrlPublic = `${process.env.REACT_APP_BASE_URL}/public/listing/`;

export const apiUrlAuthenticatedSource = `${process.env.REACT_APP_BASE_URL}/listing?`;

export const apiUrlAuthenticated = `${process.env.REACT_APP_BASE_URL}/listing/`;

export const apiQueryAuthenticated = `${process.env.REACT_APP_BASE_URL}/listing?`;

export const apiQueryPublic = `${process.env.REACT_APP_BASE_URL}/public/listing?`;

export const fetchProfileData = `${process.env.REACT_APP_BASE_URL}/profiles/`;

export const updateProfilePicture = `${process.env.REACT_APP_BASE_URL}/profiles/upload/`;

export const cart_URL = `${process.env.REACT_APP_BASE_URL}/cart/`;

export const addToCart_URL = `${process.env.REACT_APP_BASE_URL}/cart/add/`;

export const removeFromCart_URL = `${process.env.REACT_APP_BASE_URL}/cart/remove/`;

export const clearCart_URL = `${process.env.REACT_APP_BASE_URL}/cart/clear`;

export const favourite_URL = `${process.env.REACT_APP_BASE_URL}/favourite`;

export const emailVerification_URL = `${process.env.REACT_APP_BASE_URL}/profiles/email-verification`;

export const cartCheckout_URL = `${process.env.REACT_APP_BASE_URL}/payments/initiate`;

export const purchasedSites_URL = `${process.env.REACT_APP_BASE_URL}/purchased`;

export const verificationEmailAllUser_URL = `${process.env.REACT_APP_BASE_URL}/profiles/email-verification-by-email-id`;

export const availableFacilities = [
  "AIRPORT",
  "BIKE_SHARING",
  "BUS_STOP",
  "CAFE",
  "CARPOOL_PARKING",
  "EATERIES",
  "EV_PARKING",
  "FUEL_STATION",
  "HOTEL",
  "LIGHTING",
  "LODGING",
  "MALL",
  "METRO_STATION",
  "MUSEUM",
  "NATURE",
  "PARKING_LOT",
  "RECREATION_AREA",
  "RESTAURANT",
  "RESTROOM",
  "SHOPPING",
  "SUPERMARKET",
  "TAXI_STAND",
  "TRAIN_STATION",
  "TRAM_STOP",
  "WASHROOM",
  "WIFI",
];

export const parkingTypeArray = [
  "ALONG_MOTORWAY",
  "PARKING_GARAGE",
  "PARKING_LOT",
  "ON_DRIVEWAY",
  "ON_STREET",
  "UNDERGROUND_GARAGE",
];
