import React, { useContext } from "react";
import { parkingTypeArray } from "../utils/constants";
import { useState, useRef, useEffect } from "react";
import { getAuth } from "firebase/auth";
import { apiUrlAuthenticated, availableFacilities } from "../utils/constants";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import Button from "@mui/joy/Button";
import Modal from "react-modal";
import LoadingModal from "../utils/LoadingModal";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import textProperties from "../utils/textProperties";
import { textPropertiesAdmin } from "../utils/textPropertiesAdmin";
import "./styles.css";
import { MyContext } from "../../App";
function SiteEditor({ id }) {
  const [inputKey, setInputKey] = useState(0);
  const { globalImageEditor, setGlobalImageEditor } = useContext(MyContext);
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [field, setFieldData] = useState("Edit a Site !");
  const [siteId, setSiteId] = useState("");
  const [openLoadingModal, setOpenLoadingModel] = useState(false);
  const [showImageUploadDiv, setImageUploadDiv] = useState(globalImageEditor);
  const [imageInputs, setImageInputs] = useState([{ type: null, file: null }]);
  const [editorData, setEditorData] = useState();
  const [valueOfSite, setValueOfSite] = useState("");
  const targetDivRef = useRef(null);
  const [l, sl] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    access: "",
    siteType: "",
    parkingSupport: true,
    parkingType: "",
    pincode: "",
    city: "",
    propertyType: "",
    area: 0,
    numberOfParking: 0,
    vehicleSuitability: [""],
    facilities: [""],
    state: "",
    locality: "",
    address: "",
    availableLoad: 0,
    about: "",
    price: {
      inclTax: "",
      exclTax: "",
    },
    ownerName: "",
    ownerContact: "",
  });
  let dummyData = {
    name: "",
    access: "",
    siteType: "",
    parkingSupport: true,
    parkingType: "",
    pincode: "",
    city: "",
    propertyType: "",
    area: 0,
    numberOfParking: 0,
    vehicleSuitability: [""],
    facilities: [""],
    state: "",
    locality: "",
    address: "",
    availableLoad: 0,
    about: "",
    price: {
      inclTax: "",
      exclTax: "",
    },
    ownerName: "",
    ownerContact: "",
  };

  const mergeFormDataWithEditorData = () => {
    // Assuming `editorData` is the object containing the data to be merged
    const updatedFormData = { ...formData };

    // Iterate over the keys in formDataurl
    Object.keys(updatedFormData)?.forEach((key) => {
      // Check if the key exists in editorData
      if (dummyData?.hasOwnProperty(key)) {
        // Update the value in formData with the value from editorData
        updatedFormData[key] = dummyData[key];
      }
    });

    // Set the updated form data
    setFormData(updatedFormData);
  };

  // Call the function when needed, for example, after fetching editorData

  let toastNum = true;
  const notify = (toastMessage) => {
    if (toastNum) {
      toast.error(toastMessage, {
        position: toast.POSITION.TOP_LEFT,
        autoClose: 3000,
      });
      toastNum = false;
      setInterval(() => {
        toastNum = true;
      }, 5000);
    }
  };

  const notifySuccess = (toastMessage) => {
    toast.success(toastMessage, {
      position: toast.POSITION.TOP_LEFT,
      autoClose: 3000,
    });
  };

  const handleNumericInput = (e) => {
    const { name, value } = e.target;
    const numericValue = parseFloat(value);
    setFormData({ ...formData, [name]: numericValue });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePriceChange = (e) => {
    const { value } = e.target;
    const numericValue = parseFloat(value);
    setFormData({
      ...formData,
      price: {
        inclTax: Math.round(numericValue * 1.18),
        exclTax: numericValue,
      },
    });
  };

  const handleInputArrayChange = (e) => {
    const { name, value } = e.target;
    let newValue = value.split(",").map((item) => item.trim());
    setFormData({ ...formData, [name]: newValue });
  };

  const handleNumericInputForPincode = (e) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/\D/g, "");
    const maxLength = 6;
    const truncatedValue = numericValue.slice(0, maxLength);
    setFormData({ ...formData, [name]: truncatedValue });
  };

  const handleAccessChange = (e) => {
    setFormData({ ...formData, access: e.target.value });
  };

  const handlePropertyChange = (e) => {
    setFormData({ ...formData, propertyType: e.target.value });
  };

  const handleSiteTypeChange = (e) => {
    setFormData({ ...formData, siteType: e.target.value });
  };

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleFacilityChange = (e) => {
    const { value, checked } = e.target;
    let updatedFacilities;

    if (checked) {
      updatedFacilities = [...selectedFacilities, value];
    } else {
      updatedFacilities = selectedFacilities.filter(
        (facility) => facility !== value
      );
    }

    setSelectedFacilities(updatedFacilities);

    setFormData({ ...formData, facilities: updatedFacilities });
  };

  const handleFocus = (fieldName) => {
    setFieldData(textPropertiesAdmin?.[fieldName]);
  };

  const handleBlur = (fieldName) => {
    setFieldData("** All the fields are mandatory. **");
  };

  const auth = getAuth();
  const user = auth.currentUser;

  async function getRefreshedToken() {
    try {
      const user = auth.currentUser;
      if (user) {
        const refreshedToken = await user.getIdToken();
        return refreshedToken;
      }
      return null;
    } catch (error) {
      console.error("Error refreshing token:", error);
      return null;
    }
  }

  async function fetchData() {
    setSiteId(localStorage.getItem("siteId"));

    if (siteId) {
      try {
        const apiUrl = apiUrlAuthenticated + siteId;
        const user = auth.currentUser;
        const refreshedToken = await getRefreshedToken();
        if (user && user?.emailVerified) {
        }
        const headers = new Headers({
          accept: "application/json",
          Authorization: `Bearer ${refreshedToken}`,
        });

        const response = await fetch(apiUrl, {
          method: "GET",
          headers: user ? headers : undefined,
        });

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const { data } = await response.json();
        dummyData = { ...dummyData, ...data };

        setEditorData(data);

        return data;
      } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
      }
    }
  }

  const handleLoadData = async () => {
    await fetchData();
    mergeFormDataWithEditorData();
  };

  async function sendFormData(formData) {
    const inputElements = document.getElementsByName("name");
    if (inputElements.length > 0) {
      inputElements[0].focus();
    }
    for (let key in formData) {
      if (
        typeof key === "string" &&
        key !== "ownerName" &&
        key !== "ownerContact"
      ) {
        if (formData[key] === "") {
          return new Promise((resolve) => {
            const inputElements = document.getElementsByName(key);
            if (inputElements.length > 0) {
              inputElements[0].focus();
              setTimeout(resolve, 0); // Resolve after the focus operation completes
            } else {
              resolve(); // Resolve immediately if no input element found
            }
          });
        }
      }
      if (Array.isArray(key)) {
        if (formData[key].length === 0) {
          return new Promise((resolve) => {
            const inputElements = document.getElementsByName(key);
            if (inputElements.length > 0) {
              inputElements[0].focus();
              setTimeout(resolve, 0); // Resolve after the focus operation completes
            } else {
              resolve(); // Resolve immediately if no input element found
            }
          });
        }
      }
    }

    const url = apiUrlAuthenticated + localStorage.getItem("siteId");

    try {
      setOpenLoadingModel(true);
      const refreshedUser = await user.getIdToken();

      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${refreshedUser}`,
          accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        setOpenLoadingModel(false);
        throw new Error(`Request failed with status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Response:", data);
      setSiteId(siteId);
      setModalOpen(true);
      setOpenLoadingModel(false);
      notifySuccess(textProperties.notify.siteAdded);
    } catch (error) {
      setOpenLoadingModel(false);
      notify(textProperties.notify.adminMandatoryFieldError);
      console.error("Error:", error);
    }
  }

  const sendImageData = async () => {
    setOpenLoadingModel(true);
    try {
      const apiUrl = apiUrlAuthenticated + siteId + "/upload";

      for (const { file, type } of imageInputs) {
        if (file) {
          await uploadImage(apiUrl, file, type);
        }
      }

      setOpenLoadingModel(false);
      notifySuccess(textProperties.notify.imagesUploaded);
      fetchData();
    } catch (error) {
      console.error("Error uploading images:", error);
      setOpenLoadingModel(false);
    }
  };

  const uploadImage = async (apiUrl, file, type) => {
    try {
      const refreshedUser = await user.getIdToken();

      const formDataToSend = new FormData();
      formDataToSend.append("files", file);
      formDataToSend.append("type", type);
      const header = {
        Authorization: `Bearer ${refreshedUser}`,
      };
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: header,
        body: formDataToSend,
      });

      if (!response.ok) {
        throw new Error(`Failed to upload ${type} image`);
      }

      console.log(`${type} image uploaded successfully!`);
    } catch (error) {
      throw new Error(`Error uploading ${type} image: ${error.message}`);
    }
  };
  useEffect(() => {
    sl(true);
    const h = async () => {
      await fetchData().then(() => {
        mergeFormDataWithEditorData();
      });
    };
    h();
  }, [l, siteId]);
  const handleSubmit = (e) => {
    e.preventDefault();

    sendFormData(formData);
  };

  const handleAddImages = () => {
    setModalOpen(false);
    setImageUploadDiv(true);
  };

  useEffect(() => {
    if (showImageUploadDiv && targetDivRef.current) {
      setTimeout(() => {
        targetDivRef.current.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  }, [showImageUploadDiv]);

  const handleCancel = () => {
    setModalOpen(false);
  };

  const handleAddImageInput = () => {
    setImageInputs((prevInputs) => [...prevInputs, { type: null, file: null }]);
  };

  const handleImageTypeChange = (index, newType) => {
    setImageInputs((prevInputs) =>
      prevInputs.map((input, i) =>
        i === index ? { ...input, type: newType } : input
      )
    );
  };

  const handleImageFileChange = (index, file) => {
    const img = new Image();
    img.onload = function () {
      const aspectRatio = img.width / img.height;
      if (aspectRatio >= 2 / 3 && aspectRatio <= 3 / 2) {
        setImageInputs((prevInputs) =>
          prevInputs.map((input, i) =>
            i === index ? { ...input, file } : input
          )
        );
      } else {
        file = null;
        setInputKey((prevKey) => prevKey + 1);
        setImageInputs((prevInputs) =>
          prevInputs.map((input, i) =>
            i === index ? { ...input, file: null } : input
          )
        );
        alert("Image aspect ratio must be between 2:3 and 3:2");
      }
    };
    img.src = URL.createObjectURL(file); // Load the image
  };

  const handleRemoveImageInput = (index) => {
    setImageInputs((prevInputs) => prevInputs.filter((_, i) => i !== index));
  };

  return (
    <>
      <ToastContainer />
      <LoadingModal
        open={openLoadingModal}
        onClose={openLoadingModal}
        text={"Uploading! Please wait..."}
      />
      <div className="home-container">
        <div className="adminHeading">Modify Site Data</div>
        {!showImageUploadDiv && (
          <div className="adminSubContainer">
            <div className="addASiteFields">
              {siteId == "" && (
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    justifyContent: "center",
                  }}
                >
                  <input
                    onChange={(e) => {
                      setValueOfSite(e.target.value);
                    }}
                  />

                  <button
                    onClick={() => {
                      setSiteId(valueOfSite);
                      localStorage.setItem("siteId", valueOfSite);
                    }}
                  >
                    submit
                  </button>
                </div>
              )}

              <form onSubmit={handleSubmit}>
                <div className="row marginTop20">
                  <div className="column">
                    <label>
                      <span className="fieldname">Site Name</span>
                      <input
                        type="text"
                        className="special-input"
                        name="name"
                        value={formData.name}
                        onFocus={() => handleFocus("name")}
                        onBlur={() => handleBlur()}
                        onChange={handleInputChange}
                      />
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="column">
                    <label>
                      <span className="fieldname">City</span>
                      <input
                        type="text"
                        className="special-input"
                        name="city"
                        value={formData.city}
                        onFocus={() => handleFocus("city")}
                        onBlur={() => handleBlur()}
                        onChange={handleInputChange}
                      />
                    </label>
                  </div>
                  <div className="column">
                    <label>
                      <span className="fieldname">Pincode</span>
                      <input
                        type="text"
                        name="pincode"
                        className="special-input"
                        value={formData.pincode}
                        onFocus={() => handleFocus("pincode")}
                        onBlur={() => handleBlur()}
                        onChange={handleNumericInputForPincode}
                      />
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="column">
                    <label>
                      <span className="fieldname">State</span>
                      <input
                        type="text"
                        className="special-input"
                        name="state"
                        value={formData.state}
                        onFocus={() => handleFocus("state")}
                        onBlur={() => handleBlur()}
                        onChange={handleInputChange}
                      />
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="column">
                    <label>
                      <span className="fieldname">Locality</span>
                      <input
                        type="text"
                        className="special-input"
                        name="locality"
                        value={formData.locality}
                        onFocus={() => handleFocus("locality")}
                        onBlur={() => handleBlur()}
                        onChange={handleInputChange}
                      />
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="column">
                    <label>
                      <span className="fieldname">Address</span>
                      <input
                        type="text"
                        className="special-input"
                        name="address"
                        value={formData.address}
                        onFocus={() => handleFocus("address")}
                        onBlur={() => handleBlur()}
                        onChange={handleInputChange}
                      />
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="column">
                    <label>
                      <span className="fieldname">Prop. Type</span>
                      <select
                        name="propertyType"
                        value={formData.propertyType}
                        onFocus={() => handleFocus("propertyType")}
                        onBlur={() => handleBlur()}
                        onChange={handlePropertyChange}
                      >
                        <option value=""></option>
                        <option value="RWAs">RWA</option>
                        <option value="Hotel">Hotel</option>
                        <option value="Cafe">Cafe</option>
                        <option value="RESTAURANTS">Restaurant</option>
                        <option value="HIGH_SIDE_HOTELS">
                          High Side Hotel
                        </option>
                        <option value="HIGH_SIDE_LAND">High Side Land</option>
                        <option value="WORKSHOPS">Workshop</option>
                        <option value="IT_PARKS">IT Park</option>
                      </select>
                    </label>
                  </div>
                  <div className="column">
                    <label>
                      <span
                        className="fieldname"
                        style={{ marginLeft: "-25px" }}
                      >
                        Site type
                      </span>
                      <select
                        name="siteType"
                        value={formData.siteType}
                        onFocus={() => handleFocus("siteType")}
                        onBlur={() => handleBlur()}
                        onChange={handleSiteTypeChange}
                      >
                        <option value=""></option>
                        <option value="Residential">Residential</option>
                        <option value="Commercial">Commercial</option>
                        <option value="Properitary">Properitary</option>
                        <option value="Govt Body PMPL">Govt Body PMPL</option>
                        <option value="PCMS Parking">PCMS Parking</option>
                        <option value="Theatre">Theatre</option>
                        <option value="Temple">Temple</option>
                        <option value="Heritage Site">Heritage Site</option>
                        <option value="Other">Other</option>
                      </select>
                      <input
                        type="text"
                        className="special-input"
                        name="siteType"
                        value={formData.siteType}
                        onFocus={() => handleFocus("siteType")}
                        onBlur={() => handleBlur()}
                        onChange={handleSiteTypeChange}
                        style={{ marginLeft: "15px" }}
                      />
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="column">
                    <label>
                      <span className="fieldname">Parkings</span>
                      <input
                        type="number"
                        className="special-input"
                        name="numberOfParking"
                        value={formData.numberOfParking}
                        onFocus={() => handleFocus("parkings")}
                        onBlur={() => handleBlur()}
                        onChange={handleNumericInput}
                      />
                    </label>
                  </div>
                </div>

                <div className="row">
                  <div className="column">
                    <label>
                      <span className="fieldname">Access</span>
                      <select
                        name="access"
                        value={formData.access}
                        onFocus={() => handleFocus("access")}
                        onBlur={() => handleBlur()}
                        onChange={handleAccessChange}
                      >
                        <option value=""></option>
                        <option value="Public">Public</option>
                        <option value="Captive">Captive</option>
                        <option value="Hybrid">Hybrid</option>
                        <option value="Restricted">Restricted</option>
                      </select>
                    </label>
                  </div>

                  <div className="column">
                    <label>
                      <span className="fieldname">Suitable for</span>
                      <select
                        name="vehicleSuitability"
                        value={formData.vehicleSuitability[3]}
                        onFocus={() => handleFocus("suitability")}
                        onBlur={() => handleBlur()}
                        onChange={handleInputArrayChange}
                      >
                        <option value=""></option>
                        <option value="2W">2W</option>
                        <option value="3W">3W</option>
                        <option value="4W">4W</option>
                        <option value="2W, 3W">2W, 3W</option>
                        <option value="2W, 4W">2W, 4W</option>
                        <option value="3W, 4W">3W, 4W</option>
                        <option value="2W, 3W, 4W">2W, 3W, 4W</option>
                      </select>
                    </label>
                  </div>

                  <div className="column">
                    <label>
                      <span className="fieldname">Parking type</span>
                      <select
                        name="parkingType"
                        value={formData.parkingType}
                        onFocus={() => handleFocus("parkingType")}
                        onBlur={() => handleBlur()}
                        onChange={handleInputArrayChange}
                      >
                        <option value=""></option>
                        {parkingTypeArray.map((parkingType) => {
                          return (
                            <option value={parkingType}>{parkingType}</option>
                          );
                        })}
                      </select>
                    </label>
                  </div>
                </div>

                <div className="row">
                  <span
                    className="fieldname"
                    style={{ marginRight: "-5px", alignItems: "center" }}
                  >
                    Facilities
                  </span>
                  <div className="column">
                    <div className="dropdown">
                      <label
                        onFocus={() => handleFocus("facilities")}
                        onBlur={() => handleBlur()}
                        onClick={handleToggleDropdown}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {selectedFacilities.map((facility, index) => (
                          <React.Fragment key={index}>
                            {facility}
                            {index < selectedFacilities.length - 1 && ", "}
                          </React.Fragment>
                        ))}

                        <ArrowDropDownIcon />
                      </label>
                      {isOpen && (
                        <div className="checkbox-options adminFacilitiesDropdown">
                          {availableFacilities.map((facility) => (
                            <label key={facility}>
                              <input
                                type="checkbox"
                                value={facility}
                                checked={formData.facilities.includes(facility)}
                                onChange={handleFacilityChange}
                              />
                              {facility}
                            </label>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="column">
                    <label>
                      <span className="fieldname">Load (kW)</span>
                      <input
                        type="number"
                        name="availableLoad"
                        onFocus={() => handleFocus("load")}
                        onBlur={() => handleBlur()}
                        value={formData.availableLoad}
                        onChange={handleNumericInput}
                      />
                    </label>
                  </div>

                  <div className="column">
                    <label>
                      <span className="fieldname">
                        Area (ft<sup>2</sup>)
                      </span>
                      <input
                        type="number"
                        name="area"
                        value={formData.area}
                        onFocus={() => handleFocus("area")}
                        onBlur={() => handleBlur()}
                        onChange={handleNumericInput}
                      />
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="column">
                    <label>
                      <span className="fieldname">About Site</span>
                      <textarea
                        type="text"
                        className="special-input"
                        name="about"
                        value={formData.about}
                        onFocus={() => handleFocus("about")}
                        onBlur={() => handleBlur()}
                        onChange={handleInputChange}
                      />
                    </label>
                  </div>
                </div>
                <div className="row"></div>
                <div className="row">
                  <div className="column">
                    <label>
                      <span className="fieldname">Price</span>
                      <input
                        type="number"
                        className="special-input"
                        name="price"
                        value={formData.price.exclTax}
                        onFocus={() => handleFocus("price")}
                        onBlur={() => handleBlur()}
                        onChange={handlePriceChange}
                      />
                      <span
                        style={{
                          width: "300px",
                          marginLeft: "20px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        (Excluding all taxes)
                      </span>
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="column">
                    <label>
                      <span className="fieldname width100">Owner's Name</span>
                      <input
                        type="text"
                        className="special-input"
                        name="ownerName"
                        value={formData.ownerName}
                        onFocus={() => handleFocus("ownerName")}
                        onBlur={() => handleBlur()}
                        onChange={handleInputChange}
                      />
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="column">
                    <label>
                      <span className="fieldname width100">
                        Owner's Contact
                      </span>
                      <input
                        type="number"
                        name="ownerContact"
                        value={formData.ownerContact}
                        onFocus={() => handleFocus("ownerContact")}
                        onBlur={() => handleBlur()}
                        onChange={handleInputChange}
                      />
                    </label>
                  </div>
                </div>
                <div className="row ">
                  <div className="column"></div>
                  <div className="column">
                    <button type="submit" className="adminSubmitBtn">
                      Submit
                    </button>
                  </div>
                  {/* <div className="column marginLeft110">
                    <button
                      onClick={handleLoadData}
                      type="button"
                      className="clear-form-data"
                    >
                      Load Data
                    </button> 
                  </div> */}
                </div>
              </form>
            </div>
            <div className="addASiteFieldDetail">
              <div className="addASiteDetailBox">
                <img
                  className="addASiteDetailBox-img"
                  src={
                    editorData?.images?.filter((e) => {
                      return e.type === "landing-page";
                    }).length > 0
                      ? editorData?.images
                          ?.slice()
                          .reverse()
                          .find((e) => e.type === "landing-page").url
                      : ""
                  }
                />
              </div>
              <div className="addASiteDetailBox">{field}</div>
            </div>
          </div>
        )}
        {showImageUploadDiv && (
          <div ref={targetDivRef} className="imagesUploaderContainer">
            <div className="addASiteFieldDetail">
              <div className="addASiteDetailBox">
                <img
                  className="addASiteDetailBox-img"
                  src={
                    editorData?.images?.filter((e) => {
                      return e.type === imageInputs[0].type;
                    }).length > 0
                      ? editorData?.images
                          ?.slice()
                          .reverse()
                          .find((e) => {
                            return e.type === imageInputs[0].type;
                          }).url
                      : ""
                  }
                />
              </div>
              {/* <div className="addASiteDetailBox">{field}</div> */}
            </div>
            <div className="uploadImagesHeading">Upload Images</div>
            <div className="uploadImagesHeading-1">
              <p>
                <strong>Name- </strong>
                <strong className="strongOfImage">{formData.name}</strong>
              </p>
              <h6>
                City- <strong className="strongOfImage">{formData.city}</strong>
              </h6>
            </div>

            {imageInputs.map((input, index) => (
              <div key={index} className="adminAddImage">
                <select
                  value={input.type}
                  onChange={(e) => handleImageTypeChange(index, e.target.value)}
                  style={{ width: "200px" }}
                >
                  <option value="">Select Image Type</option>
                  <option value="landing-page">Landing Page</option>
                  <option value="entry">Entry</option>
                  <option value="exit">Exit</option>
                  <option value="charge-location">Charge Location</option>
                  <option value="site-type">Site Type</option>
                  <option value="other">Other</option>
                </select>

                <input
                  key={inputKey}
                  type="file"
                  accept="image/*"
                  onFocus={() => handleFocus("otherImages")}
                  onBlur={() => handleBlur()}
                  style={{ width: "300px" }}
                  onChange={(e) =>
                    handleImageFileChange(index, e.target.files[0])
                  }
                />

                <span className="addImageInputField">
                  <button
                    className="addImageFieldBtn"
                    onClick={() => handleAddImageInput()}
                  >
                    +
                  </button>
                  {index === 0 && (
                    <button
                      className="removeImageFieldBtn visibilityHidden"
                      onClick={() => handleRemoveImageInput(index)}
                    ></button>
                  )}
                  {index > 0 && (
                    <button
                      className="removeImageFieldBtn"
                      onClick={() => handleRemoveImageInput(index)}
                    >
                      -
                    </button>
                  )}
                </span>
              </div>
            ))}
            {/* Your submit button or other UI components */}
            {imageInputs[0]?.file && imageInputs[0]?.type ? (
              <button className="adminImageUploadBtn" onClick={sendImageData}>
                Upload
              </button>
            ) : (
              <button
                className="adminImageUploadBtn activeBlack"
                onClick={() =>
                  notify("Please fill the required fields first !")
                }
              >
                Upload
              </button>
            )}
          </div>
        )}
      </div>
      <>
        {" "}
        <Modal
          isOpen={isModalOpen}
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: "2",
            },
            content: {
              width: "300px",
              height: "150px",
              backgroundColor: "#fff",
              margin: "auto",
              padding: "20px",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            },
          }}
        >
          <h3 style={{ padding: "20px 20px" }}>
            Site data saved! Do you want to add images?
          </h3>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <Button onClick={handleAddImages} className="clearCart-btn">
              Add images
            </Button>
            <Button
              onClick={handleCancel}
              style={{ backgroundColor: "#ea5355" }}
            >
              {textProperties.cancel}
            </Button>
          </div>
        </Modal>
      </>
    </>
  );
}

export default SiteEditor;
