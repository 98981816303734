import React, { useState } from "react";
import * as XLSX from "xlsx";
import { useGlobalState } from "../GlobalContext";
import { JSONBulkUpload } from "./JSONBulkUpload";

function ExcelUploader() {
  const [convertedData, setConvertedData] = useState(null);
  const { updateUploadData } = useGlobalState();
  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    // Get the file extension
    const extension = file.name.split(".").pop().toLowerCase();

    // Check if the file type is CSV or XLSX
    if (extension === "csv" || extension === "xlsx") {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target.result;

        if (extension === "csv") {
          // Handle CSV file
          const csvData = XLSX.read(data, { type: "binary" });
          const firstSheetName = csvData.SheetNames[0];
          const firstSheetData = XLSX.utils.sheet_to_json(
            csvData.Sheets[firstSheetName]
          );
          // Process CSV data

          setConvertedData(firstSheetData);
          updateUploadData(firstSheetData);
        } else if (extension === "xlsx") {
          // Handle XLSX (Excel) file
          const workbook = XLSX.read(data, { type: "binary" });
          const firstSheetName = workbook.SheetNames[0];
          const firstSheetData = XLSX.utils.sheet_to_json(
            workbook.Sheets[firstSheetName]
          );
          // Process XLSX data
          console.log(firstSheetData);
          setConvertedData(firstSheetData);
          updateUploadData(firstSheetData);
        }
      };

      reader.readAsBinaryString(file);
    } else {
      console.error("Unsupported file type. Please upload a CSV or XLSX file.");
    }
  };

  const handleClearSelected = () => {
    updateUploadData(null);
    setConvertedData(null);
  };

  const handleBulkUpload = () => {
    JSONBulkUpload(convertedData);
  };

  return (
    <div className="bulkUploadOperationsContainer">
      <div className="bulk-upload">
        Bulk upload:
        <input
          type="file"
          accept=".xls, .xlsx ,.csv"
          onChange={handleFileUpload}
        />
      </div>
      <div className="">
        <button className="bulkUploadBtn" onClick={handleBulkUpload}>
          Upload
        </button>
        <button className="bulkUploadClearBtn" onClick={handleClearSelected}>
          Clear
        </button>
      </div>
    </div>
  );
}

export default ExcelUploader;
