import React, { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { purchasedSites_URL } from "../utils/constants";
import { Link } from "react-router-dom";
import { useGlobalState } from "../GlobalContext";
import { titleCase } from "../utils/checks";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CancelIcon from "@mui/icons-material/Cancel";
import LoadingModal from "../utils/LoadingModal";
import "./styles.css";

export default function PurchasedSites() {
  const [purchasedData, setPurchasedData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { updateOpenPurchased } = useGlobalState();

  const fetchPurchasedData = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user?.emailVerified) {
      try {
        const apiUrl = `${purchasedSites_URL}?_cache=${new Date().getTime()}`;
        const userToken = await user.getIdToken();

        const headers = {
          Authorization: `Bearer ${userToken}`,
          accept: "application/json",
        };

        const response = await fetch(apiUrl, {
          method: "GET",
          headers,
        });

        if (!response.ok) {
          throw new Error("Failed to fetch purchased");
        }

        console.log("Purchased fetching successful");
        const { data } = await response.json();
        console.log(data.sites);
        setPurchasedData(data.sites);
      } catch (error) {
        console.error("Error fetching purchased:", error);
      } finally {
        setLoading(false); // Set loading to false regardless of success or error
      }
    }
  };

  useEffect(() => {
    fetchPurchasedData();
  }, [loading]); // Empty dependency array ensures this runs only once when the component mounts

  // Add a useEffect to force a re-render when purchasedData changes
  useEffect(() => {
    if (purchasedData) {
      setLoading(false);
    }
  }, [purchasedData]);

  if (loading) {
    // return <div>Loading...</div>; // or render a loading spinner
    return (
      <LoadingModal open={true} text={"Loading your purchased sites..."} />
    );
    // return null;
  }

  if (!purchasedData || purchasedData.length === 0) {
    // return <div>No purchased sites available</div>;
    return null;
  }

  return (
    <div className="purchasedBody">
      <div className="purchasedContainer">
        <p className="purchasedHeading">My Purchased Sites</p>
        <span
          className="cancelIconSpan"
          onClick={() => updateOpenPurchased(false)}
        >
          <CancelIcon className="cancelIcon" />
        </span>
        <div className="purchasedCardsContainer">
          {purchasedData.map((item, index) => (
            <Link to={`/product-details/${item?.siteId}`} target="blank">
              <div className="purchasedCard" key={index}>
                <Box
                  sx={{
                    width: 300,
                    height: 90,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    padding: 2,
                    border: "1px solid #ccc",
                    borderRadius: 4,
                  }}
                >
                  {item?.name ? (
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: "bold",
                        marginBottom: 1,
                      }}
                    >
                      Name:{" "}
                      {titleCase(item?.name).toString().substring(0, 27) +
                        "..."}
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: "bold",
                        marginBottom: 0,
                      }}
                    >
                      Site Id: {item?.siteId}
                    </Typography>
                  )}
                  <Box>
                    <Typography
                      sx={{
                        fontSize: 12,
                        marginBottom: 1,
                        borderBottom: "1px solid #ccc",
                      }}
                    >
                      {item?.city ? item.city : null}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontSize: 12,
                          color: "text.secondary",
                        }}
                      >
                        Purchased On
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 12,
                        }}
                      >
                        {item?.purchasedOn}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: 12,
                          color: "text.secondary",
                        }}
                      >
                        Amount
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 12,
                        }}
                      >
                        ₹ {item?.amount}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: 12,
                      color: "text.secondary",
                    }}
                  >
                    Transaction Id: {item?.transactionId}
                  </Typography>
                </Box>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
