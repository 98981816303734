import "./styles.css";
import React, { useEffect, useState, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  isNumber,
  isString,
  isStringOrArray,
  titleCase,
} from "../utils/checks";

import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import Button from "@mui/material/Button";
import CPOImage from "../../assets/images/emptycart.jpeg";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import Footer from "../Footer/Footer";
import Header from "../Header/Searchbar";
import SortMenu from "../Sort/SortMenu";
import { Link } from "react-router-dom";
import Shimmer from "../Shimmer/ShimmerListingPage";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import SignIn from "../Authentication/Signin";
import { cartAction } from "../Cart/cartActions";
import { favouriteActions } from "../Favourites/favouriteActions";
import { fetchData } from "../../assets/sourceFile";
import { getMyCart } from "../Cart/getMyCart";
import textProperties from "../utils/textProperties";
import { useGlobalState } from "../GlobalContext";
import { MyContext } from "../../App";
export default function Cards() {
  const { globalImageEditor, setGlobalImageEditor } = useContext(MyContext);
  const [data, setData] = useState([]);
  const {
    globalData,
    updateTotalSitesCount,
    totalSitesCount,
    selectedFilters,
    updateSelectedFilters,
    updateCartNumber,
  } = useGlobalState();
  const [refreshCart, setRefreshCart] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(12);
  const [hasMore, setHasMore] = useState(true);
  const [sitesInCart, setSitesInCart] = useState([]);
  const [favChanged, setFavChanged] = useState(true);
  const [isVerifyNotificationVisible, setIsVerifyNotificationVisible] =
    useState(false);
  const [wishlistStates, setWishlistStates] = useState({});
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      setUser(authUser);
    });
    return () => unsubscribe();
  }, []);
  setGlobalImageEditor(false);
  localStorage.setItem("siteId", "");
  let toastNum = true;
  const notify = (toastMessage) => {
    if (toastNum) {
      toast.error(toastMessage, {
        position: toast.POSITION.TOP_LEFT,
        autoClose: 3000,
      });
      toastNum = false;
      setInterval(() => {
        toastNum = true;
      }, 5000);
    }
  };

  useEffect(() => {
    localStorage.setItem("pageLoaded", false);
    async function fetchDataAsync() {
      try {
        if (user && user?.emailVerified) {
          // If the user is authenticated, fetch data from the authenticated API
          const { data, totalCount } = await fetchData(true);
          setData(data?.data);
          const parsedTotalCount = parseInt(totalCount, 10);
          updateTotalSitesCount(parsedTotalCount);
          setLoading(true);
          setIsVerifyNotificationVisible(true);
          setRefreshCart(!refreshCart);
        } else {
          // If the user is not authenticated, fetch data from the public API
          const { data, totalCount } = await fetchData(false);
          setData(data?.data);
          const parsedTotalCount = parseInt(totalCount, 10);
          updateTotalSitesCount(parsedTotalCount);
          setLoading(true);
          setIsVerifyNotificationVisible(true);
          setRefreshCart(!refreshCart);
        }
        localStorage.setItem("pageLoaded", true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    const delay = setTimeout(() => {
      fetchDataAsync();
    }, 2000);

    // Clear the timeout if the component unmounts or the user changes
    return () => clearTimeout(delay);
  }, [user]);

  useEffect(() => {
    if (user?.emailVerified) {
      async function fetchCartData() {
        try {
          const data = await getMyCart();
          setTimeout(() => {
            localStorage.setItem("cartNumber", data?.sites.length);
            updateCartNumber(localStorage.getItem("cartNumber"));
          }, 500);

          if (data?.sites.length > 0) {
            const existingSet = new Set(sitesInCart);
            data.sites.forEach((site) => existingSet.add(site.siteId));
            setSitesInCart(Array.from(existingSet));
          }
          setRefreshCart(true);
        } catch (error) {
          console.error("Error fetching cart data:", error);
        }
      }
      fetchCartData();
    }
  }, [refreshCart]);

  const LimitClick = () => {
    setLimit((prevLimit) => {
      const newLimit = Math.min(prevLimit + 12, totalSitesCount);
      const newLimitFilter = `limit: ${newLimit}`;
      const limitFilterIndex = selectedFilters.findIndex((filter) =>
        filter.startsWith("limit:")
      );

      if (limitFilterIndex !== -1) {
        // If it exists, replace it with the new one
        const updatedFilters = [...selectedFilters];
        updatedFilters[limitFilterIndex] = newLimitFilter;
        updateSelectedFilters(updatedFilters);
      } else {
        // If it doesn't exist, add the new filter
        updateSelectedFilters([...selectedFilters, newLimitFilter]);
      }
      if (limit >= totalSitesCount || totalSitesCount <= 10) {
        setHasMore(false);
      }

      return newLimit;
    });
  };

  useEffect(() => {
    setData(globalData);
    if (globalData) {
      localStorage.setItem("limit", Math.min(limit, totalSitesCount));
    }
  }, [globalData]);

  useEffect(() => {
    setHasMore(true);
    if (data.length < totalSitesCount) {
      setHasMore(true);
    } else if (data.length >= totalSitesCount) {
      setHasMore(false);
    }
  }, [selectedFilters.length, data.length, totalSitesCount]);

  function addItemToCart(siteId, siteName) {
    toast.success(siteName + " " + textProperties.notify.addedToCart, {
      position: toast.POSITION.TOP_LEFT,
      autoClose: 3000,
    });

    cartAction(`add/${siteId}`);
    setRefreshCart(!refreshCart);
  }

  const handleAddToFavourite = (id) => {
    // console.log("Button Clicked!");
    favouriteActions(`/add/${id}`);
    setFavChanged(!favChanged);
  };

  const handleRemoveFromFavourite = (id) => {
    console.log("Button Clicked!");
    favouriteActions(`/remove/${id}`);
    setFavChanged(!favChanged);
  };

  useEffect(() => {
    // Initialize the wishlist states based on the initial data
    const initialWishlistStates = {};
    data.forEach((item) => {
      // Use the 'favourite' field to determine the initial state
      initialWishlistStates[item.id] = item.favourite || false;
    });
    setWishlistStates(initialWishlistStates);
  }, [data]); // Run once on component mount

  const handleToggleWishlist = (itemId) => {
    // Toggle the isInWishlist state for the specific item
    setWishlistStates((prevStates) => ({
      ...prevStates,
      [itemId]: !prevStates[itemId],
    }));

    // Perform API call based on the current state
    if (!wishlistStates[itemId]) {
      // Call the API to add to the wishlist
      handleAddToFavourite(itemId);
    } else {
      // Call the API to remove from the wishlist
      handleRemoveFromFavourite(itemId);
    }
  };

  return (
    <>
      <Header />
      {isVerifyNotificationVisible && (
        <>
          {user && !user?.emailVerified && (
            <div className="notverifiednotification">
              {textProperties?.notify.verifyEmailNotification}
            </div>
          )}
        </>
      )}
      {loading ? (
        <>
          <ToastContainer theme="light" autoClose={3000} />
          <SortMenu />
          <div className="centeredContainer">
            <div className="card-container">
              {data.map((item, index) => (
                <div className="card-item" key={index}>
                  <div
                    className="wishlist-icon"
                    title={textProperties?.addToPreferences}
                  >
                    {user ? (
                      <>
                        {user.emailVerified ? (
                          <div key={item.id}>
                            {wishlistStates[item.id] ? (
                              <FavoriteRoundedIcon
                                onClick={() => handleToggleWishlist(item.id)}
                                style={{ color: "ea5355" }}
                              />
                            ) : (
                              <FavoriteBorderRoundedIcon
                                onClick={() => handleToggleWishlist(item.id)}
                              />
                            )}
                          </div>
                        ) : (
                          <FavoriteBorderRoundedIcon
                            style={{ color: "white" }}
                            onClick={() =>
                              notify(textProperties.notify.verifyEmail)
                            }
                          />
                        )}
                      </>
                    ) : (
                      <>
                        <SignIn
                          value={
                            <FavoriteBorderRoundedIcon
                              style={{ color: "white" }}
                              onClick={() =>
                                notify(textProperties.notify.signinFirst)
                              }
                            />
                          }
                        />
                      </>
                    )}
                  </div>
                  <div className="mainCardImage">
                    <Link to={`/product-details/${item?.id}`} key={index}>
                      {item?.images ? (
                        (() => {
                          for (let i = item?.images?.length - 1; i >= 0; i--) {
                            if (item.images[i].type === "landing-page") {
                              return (
                                <img
                                  key={i}
                                  src={item.images[i].url}
                                  alt={item?.name}
                                  className="card-img"
                                  loading="lazy"
                                />
                              );
                            }
                          }
                          return (
                            <img
                              alt={item?.name}
                              className="card-img"
                              loading="lazy"
                              src={CPOImage}
                            />
                          ); // Or you can return a placeholder image here
                        })()
                      ) : (
                        <img
                          key={index}
                          src={CPOImage}
                          alt={item?.name}
                          className="card-img"
                          loading="lazy"
                        />
                      )}
                    </Link>
                  </div>
                  {isString(item?.name) ? (
                    <div className="titleRating">
                      <h3 className="card-title" title={item?.name}>
                        {item?.name.toString().length > 27
                          ? item?.name.toString().substring(0, 27) + "..."
                          : item?.name}
                      </h3>
                      {/* <h3
                        className="card-title rating"
                        title={textProperties?.rating}
                      >
                        <StarIcon
                          style={{ fontSize: "18px", marginTop: "1px" }}            //add rating
                        />{" "}
                        4.3
                      </h3> */}
                    </div>
                  ) : null}
                  <div className="single-card-details">
                    <span>
                      <ul className="card-left-ul">
                        {isString(item?.city) ? (
                          <li
                            className="card-left-li"
                            title={`City: ${item?.city}`}
                          >
                            {titleCase(item?.city)}
                          </li>
                        ) : null}
                        {isString(item?.access) ? (
                          <li
                            className="card-left-li"
                            title={`Access: ${item?.access}`}
                          >
                            <span className="redText">Access:</span>{" "}
                            {item?.access}
                          </li>
                        ) : null}
                        {isString(item?.propertyType) ? (
                          <li
                            className="card-left-li"
                            title={`Property Type: ${item?.propertyType}`}
                          >
                            <span className="redText">Property type:</span>{" "}
                            {titleCase(item?.propertyType.replace(/_/g, " "))}
                          </li>
                        ) : null}
                        {isNumber(item?.area) ? (
                          <li
                            className="card-left-li"
                            title={`Area: ${item?.area} ft²`}
                          >
                            {" "}
                            <span className="redText">Area: </span>
                            {parseFloat(item?.area).toFixed(1).length > 6
                              ? parseFloat(item?.area)
                                  .toFixed(1)
                                  .toString()
                                  .substring(0, 5) + "..."
                              : parseFloat(item?.area).toFixed(1)}{" "}
                            ft²
                          </li>
                        ) : null}

                        {isNumber(item?.availableLoad) ? (
                          <li
                            className="card-left-li"
                            title={`Available load: ${item?.availableLoad}`}
                          >
                            {" "}
                            <span className="redText">Load:</span>{" "}
                            {item?.availableLoad.toString().length > 6
                              ? item.availableLoad.toString().substring(0, 6) +
                                "..."
                              : item?.availableLoad}{" "}
                            kW
                          </li>
                        ) : null}
                      </ul>
                    </span>
                    <span>
                      <ul className="card-right-ul">
                        <li className="card-right-li emptyLiField"></li>
                        {isStringOrArray(item?.facilities) ? (
                          <li
                            className="card-right-li"
                            title={`Facilities: ${titleCase(
                              item?.facilities.join(", ")
                            )}`}
                          >
                            {" "}
                            <span className="redText">
                              {textProperties.nearBy}
                            </span>{" "}
                            {titleCase(
                              item?.facilities.toString().length > 10
                                ? item?.facilities
                                    .join(", ")
                                    .toString()
                                    .substring(0, 10) + "..."
                                : item?.facilities.join(", ")
                            )}
                          </li>
                        ) : null}
                        {item?.parkingSupport ? (
                          <li
                            className="card-right-li"
                            title={textProperties.parkingSupportTrue}
                          >
                            {item?.parkingSupport}
                            <span className="redText">
                              {textProperties.parking}
                            </span>{" "}
                            Available
                          </li>
                        ) : (
                          <li
                            className="card-right-li"
                            title={textProperties.parkingSupportFalse}
                          >
                            {textProperties.parking} Unavailable
                          </li>
                        )}

                        {isNumber(item?.pincode) ? (
                          <li
                            className="card-right-li"
                            title={`Pincode: ${item?.pincode}`}
                          >
                            <span className="redText">
                              {textProperties.pincode}
                            </span>{" "}
                            {item?.pincode.length > 6
                              ? item.pincode.toString().substring(0, 6) + "..."
                              : item?.pincode}
                          </li>
                        ) : (
                          <li className="card-right-li">
                            <span className="redText">
                              {textProperties.pincode}
                            </span>{" "}
                            Unavailable
                          </li>
                        )}
                        {isStringOrArray(item?.vehicleSuitability) ? (
                          <li
                            className="card-right-li"
                            title={`Suitable for: ${item?.vehicleSuitability}`}
                          >
                            {" "}
                            <span className="redText">For:</span>{" "}
                            {item?.vehicleSuitability.toString().length > 8
                              ? item?.vehicleSuitability
                                  .toString()
                                  .substring(0, 8)
                                  .join(", ") + "..."
                              : item?.vehicleSuitability.join(", ")}
                          </li>
                        ) : null}
                      </ul>
                    </span>
                  </div>
                  <div className="addToCartContainer">
                    <div className="addToCart">
                      {user?.emailVerified ? (
                        <>
                          {item?.availableForSale === true ? (
                            sitesInCart.some((obj) => obj === item.id) ? (
                              <Link to="/cart">
                                <Button
                                  style={{
                                    color: "rgb(234, 83, 85)",
                                    border: "1px solid rgb(234, 83, 85)",
                                    borderRadius: "1.5rem",
                                  }}
                                  className="add-to-cart"
                                >
                                  {textProperties.viewInCart}
                                  <ShoppingCartCheckoutIcon
                                    style={{
                                      fontSize: "20px",
                                      marginLeft: "5px",
                                    }}
                                  />
                                </Button>
                              </Link>
                            ) : (
                              <Button
                                style={{
                                  color: "white",
                                  backgroundColor: "rgb(234, 83, 85)",
                                  borderRadius: "1.5rem",
                                  border: "1px solid rgb(234, 83, 85)",
                                }}
                                className="add-to-cart"
                                onClick={() =>
                                  addItemToCart(item.id, item.name)
                                }
                              >
                                {textProperties.addToCart}{" "}
                                <AddShoppingCartIcon
                                  style={{
                                    fontSize: "20px",
                                    marginLeft: "5px",
                                  }}
                                />
                              </Button>
                            )
                          ) : (
                            <>
                              {" "}
                              <Button
                                style={{
                                  color: "white",
                                  backgroundColor: "#919191",
                                  borderRadius: "1.5rem",
                                }}
                                className="add-to-cart"
                              >
                                sold out
                              </Button>
                            </>
                          )}{" "}
                        </>
                      ) : (
                        <>
                          {user && !user?.emailVerified ? (
                            <Button
                              style={{
                                color: "white",
                                backgroundColor: "#919191",
                                borderRadius: "1.5rem",
                              }}
                              className="add-to-cart"
                              onClick={() =>
                                notify(textProperties.notify.verifyEmail)
                              }
                            >
                              {textProperties.addToCart}
                            </Button>
                          ) : (
                            <SignIn
                              value={
                                <Button
                                  style={{
                                    color: "white",
                                    backgroundColor: "#919191",
                                    borderRadius: "1.5rem",
                                  }}
                                  className="add-to-cart"
                                  onClick={() =>
                                    notify(textProperties.notify.signinFirst)
                                  }
                                >
                                  {textProperties.addToCart}
                                </Button>
                              }
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {totalSitesCount > 0 && (
            <div className="loadMoreBtn">
              <div className="loadMoreComp">
                <span className="totalSitesCount">
                  {data?.length}
                  {" / "}
                  {totalSitesCount}
                </span>
                {hasMore && (
                  <ExpandCircleDownIcon
                    onClick={LimitClick}
                    className="loadMoreIcon"
                  />
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        <Shimmer />
      )}
      <div className="footerElement">
        <Footer />
      </div>
    </>
  );
}
