import { getAuth } from "firebase/auth";
import { favourite_URL } from "../utils/constants";

export async function fetchMyFavorites() {
  const auth = getAuth();
  const user = auth.currentUser;

  if (user) {
    try {
      const apiUrl = favourite_URL;
      console.log("Fetching favourites: " + apiUrl);
      const userToken = await user.getIdToken(/* forceRefresh */ true);

      const headers = {
        Authorization: `Bearer ${userToken}`,
        accept: "application/json",
      };

      const response = await fetch(apiUrl, {
        method: "GET",
        headers,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch favorites");
      }
      console.log("Favourite fetching successful");
      const { data } = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching favourites:", error);
      throw error;
    }
  }
}
