import { getAuth } from "firebase/auth";
import { emailVerification_URL } from "../utils/constants";

export async function sendEmailVerificationEmail() {
  const auth = getAuth();
  const user = auth.currentUser;

  try {
    let apiUrl = emailVerification_URL;
    const userToken = await user.getIdToken();
    const headers = {
      Authorization: `Bearer ${userToken}`,
      accept: "application/json",
    };
    const response = await fetch(apiUrl, {
      method: "POST",
      headers,
    });

    if (!response.ok) {
      throw new Error("Failed to send verification email");
    }
  } catch (error) {
    console.error("Error sending verification email:", error);
    throw error;
  }
}
