import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { createContext } from "react";
import { GlobalStateProvider } from "./components/GlobalContext";
import LoadingBar from "react-top-loading-bar";
import ListingPage from "./components/Listing/ListingPage";
import ProductDetails from "./components/ProductDetails/ProductDetails";
import Signup from "./components/Authentication/Signup";
import PaymentConfirmation from "./components/Payments/PaymentConfirmation";
import CartItems from "./components/Cart/CartItems";
import Profile from "./components/Profile/Profile";
import AdminPortal from "./components/AdminPortal/AdminPortal";
import FAQ from "./components/FAQs/FAQ";
import "./App.css";
import { useEffect, useState } from "react";

export const MyContext = createContext();
function App() {
  const [globalIDforEdit, setGlobalIDforEdit] = useState("");
  const [globalImageEditor, setGlobalImageEditor] = useState(false);
  const [progress, setProgress] = useState(0);
  document.addEventListener("selectstart", function (e) {
    e.preventDefault();
  });
  document.addEventListener("contextmenu", function (e) {
    e.preventDefault();
  });

  document.addEventListener("dragstart", function (e) {
    if (e.target.tagName === "IMG") {
      e.preventDefault();
    }
  });
  window.addEventListener("keydown", function (e) {
    if (
      (e.ctrlKey || e.metaKey) &&
      e.shiftKey &&
      (e.key === "I" || e.key === "i")
    ) {
      e.preventDefault();
    }
  });
  document.addEventListener("keydown", function (event) {
    // Check if the pressed key is the Print Screen key
    if (
      event.key === "PrintScreen" ||
      event.key === "PrtSc" ||
      event.key === "PrtScn" ||
      event.code === 16 ||
      event.code === "ShiftLeft" ||
      event.code === "MetaLeft" ||
      event.code === 91
    ) {
      // console.log("Print Screen key pressed");
      // Perform actions to prevent or handle Print Screen
      // Note: Actual prevention may not be possible due to browser security restrictions
    }
  });

  useEffect(() => {
    const handleBeforePrint = () => {
      console.log("Print operation initiated");
      replacePDFContent();
    };

    const handleAfterPrint = () => {
      console.log("Print operation completed");
      window.location.reload();
    };

    window.addEventListener("beforeprint", handleBeforePrint);
    window.addEventListener("afterprint", handleAfterPrint);

    return () => {
      window.removeEventListener("beforeprint", handleBeforePrint);
      window.removeEventListener("afterprint", handleAfterPrint);
    };
  }, []);

  const replacePDFContent = () => {
    const pdfElement = document.getElementById("root");
    if (pdfElement) {
      pdfElement.innerHTML =
        '<div style="text-align: center;">You are not allowed to print this page.</div>';
    }
  };

  document.addEventListener("dblclick", function (e) {
    e.preventDefault();
  });

  useEffect(() => {
    setProgress(10);
    setTimeout(() => {
      setProgress(90);
    }, 100);
    const loaded = localStorage.getItem("pageLoaded");
    if (loaded) {
      setProgress(100);
    }
  }, []);

  return (
    <MyContext.Provider
      value={{
        setGlobalIDforEdit,
        globalIDforEdit,
        globalImageEditor,
        setGlobalImageEditor,
      }}
    >
      <GlobalStateProvider>
        <div className="App">
          <LoadingBar
            color="#ea5355"
            progress={progress}
            onLoaderFinished={() => setProgress(0)}
          />
          <Router>
            <Routes>
              <Route exact path="/" element={<ListingPage />} />
              <Route
                path="/product-details/:siteId"
                element={<ProductDetails />}
              />
              <Route path="/signup" element={<Signup />} />
              <Route path="/cart" element={<CartItems />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/admin-portal/" element={<AdminPortal />} />
              <Route path="/faq" element={<FAQ />} />

              <Route
                path="/payment-confirmation"
                element={<PaymentConfirmation />}
              />
            </Routes>
          </Router>
        </div>
      </GlobalStateProvider>
    </MyContext.Provider>
  );
}

export default App;

//          <Route path="/product-details/:siteId/login" element={<Login />} />
