import React, { useEffect, useState } from "react";
import "./styles.css";
import ApprovalIcon from "@mui/icons-material/Approval";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import HomeIcon from "@mui/icons-material/Home";
import DraftsIcon from "@mui/icons-material/Drafts";
import { Link } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import Sidebar from "./AddSite";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import Drafts from "./Drafts";
import Dashboard from "./Dashboard/Dashboard";
import LoadingModal from "../utils/LoadingModal";
import AddXLSBulk from "./AddXLSBulk";
import SiteEditor from "./SiteEditor";
function AdminPortal({ id }) {
  const [page, setPage] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [user, setUser] = useState(null);
  const [openLoadingModal, setOpenLoadingModel] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      setUser(authUser);
      getRefreshedToken();
    });

    return () => unsubscribe();
  }, [user]);

  const notify = (toastMessage) => {
    toast.error(toastMessage, {
      position: toast.POSITION.TOP_LEFT,
      autoClose: 3000,
    });
  };

  async function getRefreshedToken() {
    try {
      if (user) {
        const idToken = await user.getIdTokenResult();
        if (idToken?.claims?.role === "admin") {
          setIsAdmin(true);
          setOpenLoadingModel(false);
        }
      }
    } catch (error) {
      setOpenLoadingModel(false);
      console.error("Error refreshing token:", error);
      notify("Error: Please try refreshing the page");
      return null;
    }
  }

  useEffect(() => {
    setPage(localStorage.getItem("adminPage"));
  }, []);

  const gotoAddSite = (name) => {
    localStorage.setItem("adminPage", page);
    setPage(name);
  };

  return (
    <>
      <ToastContainer />
      <LoadingModal
        open={openLoadingModal}
        onClose={openLoadingModal}
        text={"Loading! Please wait..."}
      />
      {isAdmin ? (
        <div className="home">
          <div className="sidebar">
            <div className="top">
              <span className="logo">
                CPO Assist
                <AdminPanelSettingsIcon />
              </span>
            </div>
            <hr />
            <div className="center">
              <ul>
                <li
                  className={`${
                    page === "AddSite" ? "adminLiColored" : null
                  } sidebar-li`}
                  onClick={() => gotoAddSite("AddSite")}
                >
                  <span>Add Site</span>
                  <PersonAddAltIcon className="icon" />
                </li>
                <li
                  className={`${
                    page === "BulkUpload" ? "adminLiColored" : null
                  } sidebar-li`}
                  onClick={() => gotoAddSite("BulkUpload")}
                >
                  <span>Bulk Upload</span>
                  <DriveFolderUploadIcon className="icon" />
                </li>
                <li
                  className="sidebar-li"
                  onClick={() => gotoAddSite("Dashboard")}
                >
                  <span>Approvals</span>
                  <ApprovalIcon className="icon" />
                </li>
                <li
                  className="sidebar-li"
                  onClick={() => gotoAddSite("Drafts")}
                >
                  <span>Drafts</span>
                  <DraftsIcon className="icon" />
                </li>
                <li
                  className={`${
                    page === "SiteEditor" ? "adminLiColored" : null
                  } sidebar-li`}
                  onClick={() => gotoAddSite("SiteEditor")}
                >
                  <span>Site Editor</span>
                  <IntegrationInstructionsIcon className="icon" />
                </li>
                <li className="sidebar-li">
                  <Link to="/">
                    <span>CPO Assist Home</span>
                  </Link>
                  <HomeIcon className="icon" />
                </li>
              </ul>
            </div>
          </div>
          <div className="home-container">
            {page === "AddSite" && <Sidebar />}
            {page === "Drafts" && <Drafts />}
            {page === "Dashboard" && <Dashboard />}
            {page === "BulkUpload" && <AddXLSBulk />}
            {page === "SiteEditor" && <SiteEditor id={id} />}
          </div>
        </div>
      ) : (
        <div className="adminNoAccess">
          <div className="adminNoAccessText">
            You do not have permission to access this page.
          </div>
          <button className="backtohomebtnclass" onClick={() => navigate("/")}>
            Home
          </button>
        </div>
      )}
    </>
  );
}

export default AdminPortal;
