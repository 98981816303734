import * as React from "react";
import { Link } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import Modal from "react-modal";
import PopperComponent from "../utils/PopperComponent";
import { getMyCart } from "./getMyCart";
import { useGlobalState } from "../GlobalContext";
import { cartAction } from "./cartActions";
import { favouriteActions } from "../Favourites/favouriteActions";
import LoadingModal from "../utils/LoadingModal";
import CPOImage from "../../assets/images/emptycart.jpeg";
import GuestUserPage from "../utils/GuestUserPage";
import Card from "@mui/joy/Card";
import Footer from "../Footer/Footer";
import AspectRatio from "@mui/joy/AspectRatio";
import Button from "@mui/joy/Button";
import CardContent from "@mui/joy/CardContent";
import IconButton from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import emptyCart from "../../assets/images/emptycart.jpeg";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import "./styles.css";
import Header from "../Header/Header";
import Checkout from "./Checkout";
import textProperties from "../utils/textProperties";

export default function BasicCard() {
  const [user, setUser] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [checkoutAllowed, setCheckoutAllowed] = useState(true);
  const [cartNumber, setCartNumber] = useState(null);
  const { updateCartNumber } = useGlobalState();
  const [cartItems, setCartItems] = useState([]);
  const [cartChanged, setCartChanged] = useState(true);
  const [isOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleClearCart = () => {
    cartAction("clear");
    setCartChanged(!cartChanged);
    setCartChanged(!cartChanged);
    setCartChanged(!cartChanged);
    setModalOpen(false);
  };

  const handleCancel = () => {
    setCartChanged(!cartChanged);
    setModalOpen(false);
  };

  let toastNum = true;
  const notify = (toastMessage) => {
    if (toastNum) {
      toast.error(toastMessage, {
        position: toast.POSITION.TOP_LEFT,
        autoClose: 3000,
      });
      toastNum = false;
      setInterval(() => {
        toastNum = true;
      }, 5000);
    }
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      setUser(authUser);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    async function fetchDataAsync() {
      try {
        const data = await getMyCart();
        setCartNumber(data?.sites.length);
        updateCartNumber(data?.sites.length);
        setCartItems(data?.sites);
        setCartChanged(true);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching data:", error);
      }
    }
    fetchDataAsync();
  }, [cartChanged]); //[updateCartNumber] is removed from here by AK

  const removeFromCartCall = (siteId) => {
    cartAction(`remove/${siteId}`);
    setCartChanged(!cartChanged);
  };

  useEffect(() => {
    if (cartItems) {
      const totalPriceSum = cartItems.reduce((sum, item) => {
        return sum + parseFloat(item.price?.inclTax || 0);
      }, 0);

      setTotalPrice(totalPriceSum);
      const checkoutIsAllowed = cartItems.every(
        (item) => item.availableForSale !== false
      );
      setCheckoutAllowed(checkoutIsAllowed);
    }
    localStorage.setItem("cartNumber", cartItems?.length);
  }, [cartItems]);

  const handleAddToFavourite = (id) => {
    favouriteActions(`/add/${id}`);
    removeFromCartCall(id);
    setCartChanged(!cartChanged);
  };

  return (
    <>
      <LoadingModal open={isLoading} text={"Loading your cart..."} />
      <ToastContainer />
      <Header />
      {user !== null ? (
        <>
          {cartNumber > 0 && (
            <div className="cartWindow">
              <div className="cart-container">
                {cartItems.map((item, index) => (
                  <div className="cart-item" key={item.siteId}>
                    <Card className="cartCardItem" key={item.siteId}>
                      <div>
                        <Typography
                          level="title-lg"
                          title={textProperties.siteName + " " + item?.name}
                        >
                          {item?.name.toString().length > 34
                            ? item?.name.toString().substring(0, 34) + "..."
                            : item?.name}
                        </Typography>
                        <Typography
                          level="body-sm"
                          title={textProperties.city + " " + item?.city}
                        >
                          {item?.city}
                        </Typography>
                        <IconButton
                          aria-label={textProperties.removeFromCart}
                          variant="plain"
                          color="neutral"
                          size="sm"
                          title={textProperties.removeFromCart}
                          sx={{
                            position: "absolute",
                            top: "0.875rem",
                            right: "0.5rem",
                          }}
                        >
                          <PopperComponent
                            onRemoveClick={() =>
                              removeFromCartCall(item?.siteId)
                            }
                            onWishlistClick={() =>
                              handleAddToFavourite(item?.siteId)
                            }
                          />
                        </IconButton>
                      </div>
                      <Link to={`/product-details/${item?.siteId}`}>
                        <AspectRatio minHeight="120px" maxHeight="200px">
                          <img
                            src={
                              item?.images?.filter(
                                (e) => e.type === "landing-page"
                              ).length > 0
                                ? item?.images?.find(
                                    (e) => e.type === "landing-page"
                                  ).url
                                : CPOImage
                            }
                            loading="lazy"
                            alt={index}
                          />
                        </AspectRatio>
                      </Link>
                      <CardContent
                        orientation="horizontal"
                        className="cardContent"
                      >
                        <div>
                          <Typography level="body-xs">
                            {textProperties.totalPrice}:
                          </Typography>
                          <Typography fontSize="lg" fontWeight="lg">
                            ₹{parseFloat(item?.price?.inclTax).toFixed(2)}{" "}
                            <span
                              style={{
                                fontWeight: "lighter",
                                fontSize: "small",
                              }}
                            >
                              {textProperties.inclTax}
                            </span>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "lighter",
                                opacity: "0.6",
                                display: "block",
                              }}
                            >
                              {textProperties.billSummary}
                              {textProperties.exclTax} ₹
                              {parseFloat(item?.price?.exclTax).toFixed(2)}
                              {" + GST "}₹
                              {parseFloat(
                                item?.price?.inclTax - item?.price?.exclTax
                              ).toFixed(2)}{" "}
                              (
                              {parseFloat(
                                ((item?.price?.inclTax - item?.price?.exclTax) /
                                  item?.price?.exclTax) *
                                  100
                              ).toFixed(2)}
                              %)
                            </span>
                          </Typography>
                        </div>
                        {item?.availableForSale ? null : (
                          <span className="soldOutLogo">Sold out</span>
                        )}
                      </CardContent>
                    </Card>
                  </div>
                ))}
              </div>
            </div>
          )}{" "}
          {cartNumber === 0 && (
            <div className="emptyCartDiv">
              <img src={emptyCart} alt="empty cart" className="emptyCartImg" />
              {user?.emailVerified ? (
                <>
                  <span className="cartIsEmpty">
                    {textProperties.cartIsEmpty}
                  </span>
                  <Link to="/" className="goBackBtn">
                    <Button
                      variant="outlined"
                      className="goBackBtn"
                      size="sm"
                      sx={{ alignSelf: "center" }}
                    >
                      {textProperties.goBack}
                    </Button>
                  </Link>
                </>
              ) : (
                <>
                  <span className="cartIsEmpty">
                    {textProperties.verifyEmailToView}
                  </span>
                  <Link to="/profile" className="goBackBtn">
                    <Button
                      variant="outlined"
                      className="goBackBtn"
                      size="sm"
                      sx={{ alignSelf: "center", width: "140px" }}
                    >
                      {textProperties.goToProfile}
                    </Button>
                  </Link>
                </>
              )}
            </div>
          )}
          {cartNumber > 0 ? (
            <>
              <div className="clearCartBtn">
                <Button
                  onClick={() => setModalOpen(true)}
                  className="clearCart-btn"
                  title={textProperties.clearAllItems}
                >
                  {textProperties.clearCart}
                  <RemoveShoppingCartIcon
                    style={{ marginLeft: "5px", marginRight: "5px" }}
                  />
                </Button>
              </div>
              <div className="cart-billing">
                <div
                  className="cartAllPriced"
                  title={textProperties.totalCartAmount}
                >
                  {textProperties.total} ₹ {parseFloat(totalPrice).toFixed(2)}
                </div>
                <div className="checkout-btn-class">
                  {checkoutAllowed ? (
                    <Checkout />
                  ) : (
                    <Button
                      variant="soft"
                      endDecorator={<KeyboardArrowRight />}
                      title={textProperties.removeSoldoutSite}
                      style={{ backgroundColor: "gray", color: "white" }}
                      onClick={() => notify(textProperties.removeSoldoutSite)}
                    >
                      {textProperties.checkout}
                    </Button>
                  )}
                </div>
              </div>
            </>
          ) : null}
        </>
      ) : (
        <GuestUserPage message={textProperties.guestMessage} />
      )}
      <Footer />
      <>
        {" "}
        <Modal
          isOpen={isOpen}
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: "2",
            },
            content: {
              width: "300px",
              height: "150px",
              backgroundColor: "#fff",
              margin: "auto",
              padding: "20px",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            },
          }}
        >
          <h3 style={{ padding: "20px 20px" }}>
            {textProperties.clearCartPrompt}
          </h3>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <Button onClick={handleClearCart} className="clearCart-btn">
              {textProperties.clearCart}
            </Button>
            <Button
              onClick={handleCancel}
              style={{ backgroundColor: "#ea5355" }}
            >
              {textProperties.cancel}
            </Button>
          </div>
        </Modal>
      </>
    </>
  );
}
