import { getAuth } from "firebase/auth";
import { cartCheckout_URL } from "../utils/constants";
import Button from "@mui/joy/Button";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import textProperties from "../utils/textProperties";
import LoadingModal from "../utils/LoadingModal";
import { useState } from "react";
export async function CheckoutCall() {
  const auth = getAuth();
  const user = auth.currentUser;

  try {
    let apiUrl = cartCheckout_URL;
    const refreshedUser = await user.getIdToken();

    const headers = {
      Authorization: `Bearer ${refreshedUser}`,
      accept: "application/json",
    };

    const response = await fetch(apiUrl, {
      method: "POST",
      headers,
    });

    if (!response.ok) {
      throw new Error("Failed to checkout");
    }

    const responseData = await response.json();

    const redirectUrl =
      responseData?.data?.data?.instrumentResponse?.redirectInfo?.url;
    console.log(redirectUrl);
    console.log(responseData);

    // Redirect to the new URL
    return redirectUrl;
  } catch (error) {
    console.error("Error during checkout:", error);
    throw error;
  }
}

// using the Checkout function in a component
const Checkout = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleClick = async () => {
    try {
      setModalOpen(true);
      const redirectUrl = await CheckoutCall();
      // Redirect to the new URL
      setModalOpen(false);
      window.location.href = redirectUrl;
    } catch (error) {
      // Handle errors here
      console.error("Error during checkout:", error);
    }
  };

  return (
    <div>
      <LoadingModal open={modalOpen} text={"Redirecting to payment page..."}/>
      <Button
        variant="soft"
        endDecorator={<KeyboardArrowRight />}
        className="checkout-btn"
        title={textProperties.proceedToPurchase}
        onClick={handleClick}
      >
        {textProperties.checkout}
      </Button>
    </div>
  );
};

export default Checkout;
