import React, { useState } from "react";
import { getAuth } from "firebase/auth";
import { updateProfilePicture } from "../utils/constants";
import { Button, Grid, Modal } from "@mui/material";
import LoadingModal from "../utils/LoadingModal";

function ProfilePhotoUploader() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoadingOpen, setIsLoadingOpen] = useState(false);

  async function updateProfilePic(file) {
    setIsLoadingOpen(true);
    try {
      if (!file) {
        console.error("No file selected");
        return;
      }

      const auth = getAuth();
      const user = auth.currentUser;

      let apiUrl = updateProfilePicture;
      const refreshedUser = await user.getIdToken();
      const headers = {
        Authorization: `Bearer ${refreshedUser}`,
      };

      const formData = new FormData();
      formData.append("files", file);
      formData.append("type", "profile-pic");

      // Toggle loading before API call

      const response = await fetch(apiUrl, {
        method: "POST",
        headers,
        body: formData,
      });

      // Toggle loading after API call

      if (!response.ok) {
        throw new Error("Failed to update profile picture");
      }
      console.log("Profile picture updated successfully!");
      window.location.reload();
    } catch (error) {
      console.error("Error updating picture:", error);
      setIsLoadingOpen(false); // Ensure loading state is toggled off in case of an error
      throw error;
    }
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Set the selected file for preview
      setSelectedImage(file);
      // Open the modal
      setModalOpen(true);
    }
  };

  const handleConfirmUpload = () => {
    // Close the modal
    setModalOpen(false);
    // Perform the API call to upload the selected image
    updateProfilePic(selectedImage);
  };

  const handleCloseModal = () => {
    // Close the modal and clear the selected image
    setModalOpen(false);
    setSelectedImage(null);
  };

  return (
    <>
      {" "}
      <LoadingModal open={isLoadingOpen} text={"Uploading..."}/>
      <Grid item xs={12} md={12} mt={2}>
        <div>
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="file-input"
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="file-input">
            <Button
              variant="contained"
              disableRipple
              component="span"
              fullWidth
              style={{ backgroundColor: "#ea5355" }}
            >
              Update Profile Photo
            </Button>
          </label>
        </div>

        {/* Modal for Image Preview */}
        <Modal open={isModalOpen} onClose={handleCloseModal}>
          <div className="photoUploadModalDiv">
            {selectedImage && (
              <img src={URL.createObjectURL(selectedImage)} alt="Preview" />
            )}
            <div className="photoUploadModalButtons">
              <Button
                onClick={handleConfirmUpload}
                style={{
                  backgroundColor: "#ea5355",
                  color: "white",
                  marginTop: "20px",
                }}
              >
                Confirm Upload
              </Button>
              {/* LoadingModal is moved outside the Modal */}
              {/* Pass isLoadingOpen as a prop to LoadingModal */}
              <Button
                onClick={handleCloseModal}
                style={{
                  backgroundColor: "#000",
                  color: "white",
                  marginTop: "20px",
                }}
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>
      </Grid>
    </>
  );
}

export default ProfilePhotoUploader;
