import { apiUrlAuthenticated } from "../utils/constants";
import { getAuth } from "firebase/auth";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";

import textProperties from "../utils/textProperties";

const formDataTemplate = {
  name: "",
  access: "",
  siteType: "",
  parkingSupport: true,
  parkingType: "",
  pincode: "",
  city: "",
  propertyType: "",
  area: null,
  numberOfParking: null,
  vehicleSuitability: [""],
  facilities: [""],
  state: "",
  locality: "",
  address: "",
  availableLoad: 0,
  views: 1,
  price: {
    inclTax: 0,
    exclTax: 0,
  },
  ownerName: "",
  ownerContact: "",
};

const notify = (toastMessage) => {
  toast.error(toastMessage, {
    position: toast.POSITION.TOP_LEFT,
    autoClose: 5000,
  });
};

const notifySuccess = (toastMessage) => {
  toast.success(toastMessage, {
    position: toast.POSITION.TOP_LEFT,
    autoClose: 5000,
  });
};

export async function JSONBulkUpload(jsonData) {
  const auth = getAuth();
  const user = auth.currentUser;
  async function sendFormData(formData) {
    const url = apiUrlAuthenticated;
    try {
      const refreshedUser = await user.getIdToken();
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${refreshedUser}`,
          accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }
      console.log("Site added successfully!");
      notifySuccess(textProperties.notify.siteAdded);
    } catch (error) {
      notify(textProperties.notify.adminMandatoryFieldError);
      console.error("Error:", error);
    }
  }

  for (const site of jsonData) {
    const formData = { ...formDataTemplate };
    formData.name = site.name;
    formData.access = site.access;
    formData.siteType = site.siteType;
    formData.parkingSupport = true;
    formData.parkingType = site.parkingType;
    formData.pincode = site.pincode.toString();
    formData.city = site.city;
    formData.propertyType = site.propertyType;

    if (!["RWAs", "Hotel", "Cafe"].includes(formData.propertyType)) {
      formData.propertyType = site.propertyType
        .toUpperCase()
        .replace(/\s/g, "_");
    }
    formData.area = site.area;
    formData.numberOfParking = site.numberOfParking;
    formData.state = site.state;
    formData.locality = site.locality;
    formData.address = site.address;
    formData.availableLoad = site.availableLoad;
    formData.vehicleSuitability = site.vehicleSuitability
      .split(",")
      .map((item) => item.trim());
    formData.facilities = site.facilities
      .split(",")
      .map((item) => item.trim().toUpperCase());

    formData.facilities = site.facilities
      .split(",")
      .map((item) => item.trim().toUpperCase().replace(/\s/g, "_"));

    formData.ownerName = site.ownerName;
    formData.ownerContact = site.ownerContact.toString();
    formData.price.exclTax = site.price;
    formData.price.inclTax = site.price * 1.18;

    await sendFormData(formData);
  }

  return (
    <>
      <ToastContainer />
    </>
  );
}
