import { verificationEmailAllUser_URL } from "../utils/constants";

export async function sendEmailVerificationEmail(email) {
  try {
    let apiUrl = verificationEmailAllUser_URL;
    const bodyEmail = {
        email: email,
    }
    const headers = {
      accept: "application/json",
      "Content-Type": "application/json",
    };
    const response = await fetch(apiUrl, {
      method: "POST",
      headers,
      body: JSON.stringify(bodyEmail),
    });

    if (!response.ok) {
      throw new Error("Failed to send verification email");
    }

    console.log("email sent to: "+email);
    return response.json();

  } catch (error) {
    console.error("Error sending verification email:", error);
    throw error;
  }
}
