import "./ShimmerProductDetailsPage.css";

function Shimmer() {
  return (
    <>
    <div className="siteShimmer">
    <div className="siteShimmer-heading1"></div>
      <div className="siteShimmer-pcView">
      </div>
      <div className="siteShimmer-heading"></div>
      <div className="siteShimmer-image"></div>
      <div className="siteShimmer-paragraph4"></div>
      <div className="siteShimmer-h2"></div>
      <div className="siteShimmer-paragraph1"></div>
      <div className="siteShimmer-paragraph2"></div>
      <div className="siteShimmer-paragraph3"></div>
      <div className="siteShimmer-paragraph4"></div>
      <div className="siteShimmer-h21"></div>
      <div className="siteShimmer-h22"></div>
      <div className="siteShimmer-paragraph shimmer-paragraph-large"></div>
    </div>
    </>
  );
}

export default Shimmer;
