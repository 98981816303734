const textProperties = {
  parkingSupportTrue: "Parking support is available",
  parkingSupportFalse: "Parking support is not available",
  noPrice: "Unavailable",
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  mobileNumber: "Mobile No.",
  companyName: "Company Name",
  phoneNumber: "Company Phone",
  removeFromCart: "Remove from cart",
  clearAllItems: "Clear all items",
  clearCart: "Clear cart",
  clearCartPrompt: "Do you want to clear the cart?",
  checkout: "Check out",
  goBack: "Go back",
  totalCartAmount: "Total cart amount",
  proceedToPurchase: "Proceed to purchase",
  guestMessage: "to view in your cart",
  cartIsEmpty: "Your cart looks empty!",
  inclTax: "Incl. of all taxes",
  exclTax: "Excl. tax",
  totalPrice: "Total price: ",
  billSummary: "Pricing detail: ",
  total: "Total",
  cancel: "No, Cancel",
  signIn: "Sign in",
  emailAddress: "Email Address",
  password: "Password",
  rememberMe: "Remember me",
  signInButton: "Sign In",
  forgotPassword: "Forgot password?",
  newUser: "New User?",
  signUp: "Sign up",
  back: "Back",
  role: "Role",
  selectRole: "Select Role",
  cpoRole: "CPO - Charge Point Operator",
  guestRole: "Guest",
  lcpRole: "LCP",
  confirmPassword: "Confirm Password",
  additionalDetails: "Additional Details",
  gstNumber: "GST Number",
  panNumber: "PAN Number",
  companyAddress: "Company Address",
  companyEmail: "Company Email",
  signMeUp: "Sign Up",
  alreadyHaveAccount: "Already have an account?",
  appName: "CPO Assist",
  adminPanelSettings: "Admin Panel Settings",
  addSite: "Add Site",
  dashboard: "Dashboard",
  home: "CPO Assist Home",
  siteDataHeading: "Add Site Data",
  siteName: "Site Name:",
  city: "City:",
  state: "State:",
  locality: "Locality:",
  siteType: "Site Type:",
  numberOfParking: "Number of Parking:",
  pincode: "Pincode:",
  access: "Access:",
  suitableFor: "Suitable for:",
  facilities: "Facilities:",
  availableLoad: "Available Load (kW):",
  availableArea: "Available Area (Sq. M):",
  address: "Address:",
  ownersName: "Owner's Name:",
  ownersContact: "Owner's Contact:",
  listingImage: "Listing Page Image",
  otherImages: "Other Images (Add at least 4 images)",
  submit: "Submit",
  clearAll: "Clear All",
  bulkUpload: "Bulk Upload:",
  updateProfilePhoto: "Upload Profile Photo",
  sort: "Sort",
  sortSites: "Sort the sites",
  sortByLoad: "Available load: High → Low",
  sortByArea: "Available area: Max → Min",
  copyRightCPO: "© 2023 CPO ASSIST",
  privacyTerms: "Privacy Terms",
  companyDetails: "Company details",
  profile: "My Profile",
  myCart: "My Cart",
  adminPortal: "Admin Portal",
  propertyType: "Property Type:",
  listingCity: "City",
  listingPropertyType: "Property",
  listingPincode: "Pincode",
  parking: "Parking: ",
  nearBy: "Nearby: ",
  viewInCart: "View In Cart",
  addToCart: "Add To Cart",
  signInToView: "Sign in to view.",
  nearbyFacilities: "Nearby Facilities: ",
  aboutSite: "About Site",
  parkingSupport: "Parking Support:",
  area: "Area: ",
  views: "Views: ",
  price: "Price: ",
  tax: "Tax: ",
  buynow: "Buy now",
  totalBeforeTax: "Total before taxes: ",
  verifyEmail: "Verify Email",
  verified: "Verified",
  editProfile: "Edit Profile",
  resetPassword: "Send Password Reset Email",
  saveChanges: "Save Changes",
  verifyEmailToView: "Verify your email to view",
  goToProfile: "Go to Profile",
  myPreferences: "My preferences",
  deletePreferences: "Delete all preferences",
  goHome: "Back to home",
  homeText: "Home",
  open: "Open",
  addToPreferences: "Add to my preferences",
  rating: "Rating: 4.3/5", //Change it later
  soldout: "Sold out",
  removeSoldoutSite: "Please remove sold-out site first!",
  aboutSiteUnavailable: "Site description is not available",
  aboutNoAccess: "You may not have the access to view this",
  purchasedSites: "My Purchased Sites",
  notRequired: "Not required",

  facebook: "Facebook",
  linkedin: "LinkedIn",
  instagram: "Instagram",
  x: "X.com (formerly: Twitter)",

  CPO_FB_URL: "https://www.facebook.com/p/CPO-Assist-100086906277062/",
  CPO_LINKEDIN_URL: "https://www.linkedin.com/company/cpoassist/",
  CPO_INSTAGRAM_URL: "https://www.instagram.com/cpoassist/",
  CPO_X_URL: "https://twitter.com/cpoassist",

  notify: {
    siteAdded: "Site Added Successfully!",
    adminMandatoryFieldError:
      "Error: Please fill all the required fields correctly!",
    imagesUploaded: "Images Uploaded Successfully!",
    passwordReset: "Password reset email sent to ",
    mandatoryFieldError: "Error: Please fill all the mandatory(*) fields!",
    noPreference: "Please add a preference first!",
    signinToViewCart: "Please sign in to view your cart!",
    signinToViewProfile: "Please sign in to view your profile!",
    addedToCart: " is added to your cart!",
    signinFirst: "Please sign in first!",
    signinToBuy: "Please sign in to buy!",
    signupSuccess:
      "Successfully Signed up! Please confirm the verification email sent to you.",
    verifyEmail: "Please verify your email to access this feature!",
    verifyEmailToBuy: "Please verify your email to proceed!",
    profileFetchError: "Error fetching profile, please try again",
    cartFetchError: "Error fetching cart, please try again",
    verifyEmailNotification: "Please verify your email to access our features",
  },

  userDetails: {
    invalidFirstName:
      "Please enter a valid first name (only alphabets, max 25 characters).",
    invalidLastName:
      "Please enter a valid last name (only alphabets, max 25 characters).",
    invalidPAN:
      "Please enter a valid Indian PAN number or write N/A if not available.",
    invalidGST:
      "Please enter a valid Indian GST number or write N/A if not available.",
    invalidMobile: "Please enter a valid 10-digit mobile number.",
    invalidEmail: "Please enter a valid email address.",
    invalidPassword:
      "Password must be at least 8 characters and include at least one uppercase letter, one lowercase letter, and one special character.",
    invalidNumber:
      "Please enter a valid phone number(Only digits are allowed).",
    invalidBlankField: "Required (*), write N/A if not available",
    invalidCompanyEmail:
      "Please enter your company email address or write N/A if not available",
    gstNumber: "Please enter a valid GST number",
    panNumber: "Please enter a valid Pan number",
  },

  firebaseError: {
    "auth/invalid-email": "Enter a valid email address",
    "auth/invalid-login-credentials": "Invalid email or password",
    "auth/email-already-in-use": "This email is already in use",
    "auth/error-code:-47": "Please verify your email to login",
    "auth/internal-error": "Network error: Please try again",
    "auth/too-many-requests": "Too many requests, please try after some time",
    "auth/invalid-credential": "Invalid email or password",
    verifyEmail: "Please verify your email to login",
    "auth/user-not-found": "User not found",
    "auth/user-disabled": "User disabled",
    "auth/wrong-password": "XXXXXXXXX password",
    "auth/email-already-exists": "Email already exists",
    "auth/weak-password": "XXXXXXXX is too weak",
    "auth/invalid-email": "Invalid email",
    "auth/invalid-password": "XXXXXXX password",
    "auth/user-not-found": "User not found",
    "auth/too-many-requests": "Too many requests",
    "auth/invalid-api-key": "Invalid API key",
    "auth/invalid-user-token": "Invalid user token",
    "auth/invalid-action-code": "Invalid action code",
    "auth/invalid-continue-uri": "Invalid continue URI",
    "auth/unauthorized-continue-uri": "Unauthorized continue URI",
    "auth/expired-action-code": "Expired action code",
    "auth/invalid-action-code-format": "Invalid action code format",
  },
};

export default textProperties;
